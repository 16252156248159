import { getInteractiveSessions, getInteractiveSession, getRandomWords, postOptionalFeedback, postSessionCompleteAuditLog, postSessionStartedAuditLog, postWasSessionUseful, getPromptWords, getTopicVocabList, postSaveFlashcardProgress, getTestYourKnowledgePrompts } from "src/server/endpoints/learning-app";

export class LearningAppApi {
    static postWasSessionUseful(choice: string, user: any, uniqueId: string, sessionId?: string): Promise<any> {
        return postWasSessionUseful(choice, user, uniqueId, sessionId);
    }
    static postSaveFlashcardProgress(userId?: string, topicId?: any, wordsLearned?: any, wordsStillLearning?: string): Promise<any> {
        return postSaveFlashcardProgress(userId, topicId, wordsLearned, wordsStillLearning);
    }
    static postOptionalFeedback(message: string, uniqueId: string): Promise<any> {
        return postOptionalFeedback(message, uniqueId,);
    }
    static postSessionStartedAuditLog(user: any, languageId?: string, levelId?: string, sessionId?: string): Promise<any> {
        return postSessionStartedAuditLog(user, languageId, levelId, sessionId);
    }
    static postSessionCompleteAuditLog(user: any, results: any, sessionId?: string): Promise<any> {
        return postSessionCompleteAuditLog(user, results, sessionId);
    }
    static getRandomWords(ageGroup: string, basicPOScategory: any, level: number, numberOfWords: number, topics: any, languageId: any): Promise<any> {
        return getRandomWords(ageGroup, basicPOScategory, level, numberOfWords, topics, languageId);
    }
    static getInteractiveSessions(topicId: string, userId: string): Promise<any> {
        return getInteractiveSessions(topicId, userId);
    }
    static getInteractiveSession(sessionId: string): Promise<any> {
        return getInteractiveSession(sessionId);
    }
    static getTestYourKnowledgePrompts(topicId?: string): Promise<any> {
        return getTestYourKnowledgePrompts(topicId);
    }
    static getTopicVocabList(topicId?: string, userId?: string): Promise<any> {
        return getTopicVocabList(topicId, userId);
    }
    static getPromptWords(wordBankIds: any): Promise<any> {
        return getPromptWords(wordBankIds);
    }
}