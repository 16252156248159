import React, { useEffect } from "react";
import { HiOutlineArrowRight, HiOutlineLockClosed } from "react-icons/hi";
import { formatDistanceToNow } from "date-fns";
import { ReactComponent as Book } from "src/assets/icons/book.svg";
import { Topic } from "src/interfaces/resources";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "src/Routes";
import clsx from "clsx";
interface ICard {
  topic: Topic;
  practice?: boolean;
  level?: string;
  topicId?: string;
  curriculumId?: string;
  parentDivisionId?: string;
}
export default function Card({
  topic,
  practice,
  level,
  topicId,
  parentDivisionId,
  curriculumId,
}: ICard) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userId } = useParams();
  const [colorTheme, setColorTheme] = React.useState<string>("");

  const handleClick = () => {
    if (topic?.isLocked) return;
    navigate(
      `${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}/${level}/${topic.order}/${topic.title}/${topicId}`
    );
  };

  useEffect(() => {
    const color =
      level === "Beginner"
        ? "lingawa-teal"
        : level === "Elementary"
        ? "lingawa-orange"
        : level === "Intermediate"
        ? "lingawa-primary"
        : "lingawa-teal";

    setColorTheme(color);
  }, [level]);

  return (
    <div
      className={`w-full border-[1px] ${
        topic.isLocked ? "border-gray-200" : `border-${colorTheme}`
      }  white-card-rounded p-3 cursor-pointer hover:bg-gray-100  transition-all duration-200 flex flex-col justify-between ${
        topic.isLocked && "pointer-events-none"
      }`}
      onClick={handleClick}
    >
      <div className="flex gap-2 items-center justify-between">
        {/* Create a circle around the text */}

        <div className="">
          <img
            src={
              topic?.imageUrl ||
              `${process.env.REACT_APP_ASSET_CDN}/lingawa-icon.png`
            }
            alt={topic.title}
            className={clsx({
              "border-gray-300 max-h-10 object-contain border-2 rounded-xl":
                topic.isLocked,
              "border-lingawa-teal max-h-10 object-contain border-2 rounded-xl":
                level === "Beginner" && !topic.isLocked,
              "border-lingawa-orange max-h-10 object-contain border-2 rounded-xl":
                level === "Elementary" && !topic.isLocked,
              "border-lingawa-primary max-h-10 object-contain border-2 rounded-xl":
                level === "Intermediate" && !topic.isLocked,
            })}
          />
        </div>
        <h2 className="font-semibold md:text-sm">{`${topic.order}. ${topic.title}`}</h2>
        {/* {!topic?.isLocked && (
          <LastPracticedTime
            practice={practice}
            lastPracticedDate={topic.lastPracticed}
          />
        )} */}
        <button className="border rounded-[9px] p-2 self-start">
          {topic?.isLocked ? (
            <HiOutlineLockClosed className={`text-${colorTheme}`} />
          ) : (
            <HiOutlineArrowRight />
          )}
        </button>
      </div>
      {/* {practice ? (
        <button className="flex gap-2 items-center rounded-[9px] bg-primary-pale py-3 px-2">
          <Book />
          <p className="text-primary font-medium">Practice Lesson</p>
        </button>
      ) : (
        <button className="border rounded-[9px] p-2 self-start">
          {topic?.isLocked ? <HiOutlineLockClosed /> : <HiOutlineArrowRight />}
        </button>
      )} */}
    </div>
  );
}

function LastPracticedTime({ lastPracticedDate, practice }: any) {
  if (!lastPracticedDate)
    return (
      <p
        className={`${
          practice ? "text-primary-gray" : "text-secondary"
        } font-medium `}
      >
        Practice!
      </p>
    );

  const timeAgo = formatDistanceToNow(new Date(lastPracticedDate), {
    addSuffix: true,
  });
  return (
    <p
      className={`${
        practice ? "text-primary-gray" : "text-secondary"
      } font-medium `}
    >
      Practiced {timeAgo}
    </p>
  );
}
