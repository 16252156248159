import { useState, useEffect } from 'react';

export function useIsMdScreen() {
  const [isMd, setIsMd] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia('(min-width: 768px)');

      // Update the state based on the initial match
      setIsMd(mediaQuery.matches);

      // Handler for media query change events
      const handleChange = (e: { matches: boolean | ((prevState: boolean) => boolean); }) => {
        setIsMd(e.matches);
      };

      // Add the listener
      mediaQuery.addEventListener('change', handleChange);

      // Cleanup the listener on component unmount
      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, []);

  return isMd;
}