// MultipleChoicePrompt.tsx
import React, { useEffect, useState } from "react";
import { Prompt } from "./types";

interface MultipleChoicePromptProps {
  prompt: Prompt;
  onNext: (isCorrect: boolean) => void;
}

const MultipleChoicePrompt: React.FC<MultipleChoicePromptProps> = ({
  prompt,
  onNext,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const handleOptionSelect = (optionId: string) => {
    setSelectedOption(optionId);
  };

  useEffect(() => {
    setSelectedOption("");
    setHasSubmitted(false); // Reset when new prompt arrives
  }, [prompt._id]);

  const handleSubmit = () => {
    if (hasSubmitted) return; // Prevent double submission
    setHasSubmitted(true);

    const isCorrect =
      selectedOption ===
      prompt.options?.find((opt) => opt.word === prompt.correctAnswer)?._id;

    onNext(isCorrect || false);
  };

  console.log("hasSubmitted", hasSubmitted);

  return (
    <div className="relative max-w-md mx-auto p-6 rounded-xl shadow-lg bg-gradient-to-br from-lingawa-primary to-primary transition-transform transform">
      {/* Fun Header */}
      <div className="flex items-center justify-center mb-4">
        <span className="select-none">🤩</span>
        <h2 className="text-xs font-bold text-white ml-2">
          Choose the Correct Option!
        </h2>
      </div>

      {/* Prompt Word */}
      <p className="text-center text-gray-300 text-lg mb-6">
        Translate:{" "}
        <span className="font-semibold text-white">
          {prompt.word.englishTranslation}
        </span>
      </p>

      {/* Display image if available */}
      {prompt.word.imageUrl && (
        <div className="flex justify-center mb-4">
          <img
            src={prompt.word.imageUrl}
            alt={prompt.word.englishTranslation}
            className="max-h-40 object-contain rounded shadow-md"
          />
        </div>
      )}

      {/* Options as Cards in 2x2 Grid */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        {prompt.options?.map((option) => {
          const isSelected = selectedOption === option._id;
          return (
            <div
              key={option._id}
              onClick={() => handleOptionSelect(option._id)}
              className={`
              cursor-pointer p-4 rounded-xl shadow-md bg-white hover:scale-105 transform transition-transform text-center font-medium 
              ${
                isSelected
                  ? "ring-4 ring-orange-400"
                  : "ring-2 ring-transparent"
              }
            `}
            >
              {option.word}
            </div>
          );
        })}
      </div>

      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        disabled={!selectedOption || hasSubmitted}
        className={`
        w-full py-2 px-4 rounded-xl font-semibold transition-transform transform 
        ${
          selectedOption
            ? "bg-orange-600 text-white hover:bg-orange-700"
            : "bg-gray-400 text-gray-700 cursor-not-allowed"
        }
      `}
      >
        Submit
      </button>
    </div>
  );
};

export default MultipleChoicePrompt;
