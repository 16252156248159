export const getPaymentPlanInfo = (
  paymentPlan: string,
  numberOfLessons: number,
  lessonFrequency: number
) => {
  const paymentPlanInfo: { [key: string]: string[] } = {
    "Monthly Package": [
      `${numberOfLessons * lessonFrequency} lessons with a native tutor`,
      "Variety of learning activities, including videos, audio recordings, interactive exercises, and games",
      "Access to Lingawa Virtual Immersion Program",
    ],
    "Two Taster Lessons": [
      `${numberOfLessons} lessons with a native tutor`,
      "Variety of learning activities, including videos, audio recordings, interactive exercises, and games",
      "Access to Lingawa Virtual Immersion Program",
    ],
    "Beginner Package": [
      "Learn 200 words!",
      `${numberOfLessons} lessons with a native tutor`,
      "Learn how to greet, speak about your family, learn the native pronunciations and so much more!",
      "Variety of learning activities, including videos, audio recordings, interactive exercises, and games",
      "Access to Lingawa Virtual Immersion Program",
    ],
    "Beginner & Elementary Package": [
      "Learn 700 words!",
      `${numberOfLessons} lessons with a native tutor`,
      "Learn everything in beginner package plus words about weddings, shopping, culture and so much more",
      "Variety of learning activities, including videos, audio recordings, interactive exercises, and games",
      "Access to Lingawa Virtual Immersion Program",
    ],
    "Intermediate Package (50 Lessons)": [
      "Learn 200 words!",
      `${numberOfLessons} lessons with a native tutor`,
      "Learn how to greet, speak about your family, learn the native pronunciations and so much more!",
      "Variety of learning activities, including videos, audio recordings, interactive exercises, and games",
      "Access to Lingawa Virtual Immersion Program",
    ],
    "Intermediate + Upper Intermediate Package (100 Lessons)": [
      "Learn 700 words!",
      `${numberOfLessons} lessons with a native tutor`,
      "Learn everything in beginner package plus words about weddings, shopping, culture and so much more",
      "Variety of learning activities, including videos, audio recordings, interactive exercises, and games",
      "Access to Lingawa Virtual Immersion Program",
    ],
  };

  return paymentPlanInfo[paymentPlan];
};
