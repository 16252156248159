import { SetStateAction } from "react";
import { IPhoneNumber } from "src/interfaces/contact";

export const getContactDetails = (countryCode: string): IPhoneNumber => {
  if (countryCode.toLowerCase() === "ng") {
    return {
      label: "0915 5000 123",
      phone: "+2349155000123",
    };
  }

  if (countryCode.toLowerCase() === "gb") {
    return {
      label: "020 7173 2100",
      phone: "+442071732100",
    };
  }

  return {
    label: "+1 (212) 484-1686",
    phone: "+12124841686",
  };
};

export const getWhatsappDetails = (countryCode: string): string => {
  if (
    countryCode.toLowerCase() === "us" ||
    countryCode.toLowerCase() === "ca"
  ) {
    return "https://wa.me/18453867738";
  }

  return "https://wa.me/447888870126";
};
export const getWhatsappNumber = (countryCode: string): string => {
  if (
    countryCode.toLowerCase() === "us" ||
    countryCode.toLowerCase() === "ca"
  ) {
    return "18453867738";
  }

  return "447888870126";
};

export const getContactAddress = (countryCode: string): String => {
  if (countryCode.toLowerCase() === "ng") {
    return "Lingawa Nigeria Ltd, 16 Romay Gardens, Lekki, Lagos, Nigeria, 105102";
  }

  if (countryCode.toLowerCase() === "gb") {
    return "Lingawa UK Ltd, 128 City Road, London, United Kingdom, EC1V 2NX";
  }

  return "Lingawa Inc. 447 Broadway, 2nd Floor Suite #858 New York, New York 10013";
};

export const getConsultationLink = (
  countryCode: string
): SetStateAction<string> => {
  if (
    countryCode.toLowerCase() === "us" ||
    countryCode.toLowerCase() === "ca"
  ) {
    return "https://topset.as.me/learning-pod-us";
  }

  return "https://topset.as.me/learning-pod-uk";
};

export const getOpeningHours = (countryCode: string): String => {
  if (countryCode.toLowerCase() === "gb") {
    return "9am-9pm GMT+1";
  }

  if (countryCode.toLowerCase() === "ng") {
    return "9am-9pm WAT";
  }

  return "9am-8pm EST";
};

export const getOpeningDays = (countryCode: string): String => {
  if (countryCode.toLowerCase() === "gb") {
    return "Monday to Saturday";
  }

  if (countryCode.toLowerCase() === "ng") {
    return "Monday to Saturday";
  }

  return "Monday to Sunday";
};
