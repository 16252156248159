import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "src/Routes";
import { useDirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import { getClientTimeZone } from "src/services/client";

export default function useHandleSignup() {
  const {
    actions: { signup },
    state,
  } = useDirectPayContext();
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();

  const handleSignup = async () => {
    try {
      const response = await signup({
        ...state,
        userData: {
          ...state.userData,
          timeZone: await getClientTimeZone(),
          leadFunnel: state.leadFunnel,
        },
      });
      toast.success("Signup was successful", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      localStorage.setItem("accessToken", response.data.user?.token);
      localStorage.setItem("user", JSON.stringify(response.data.user));

      if (
        state.leadFunnel === "direct-to-pay-flow-1" ||
        state.leadFunnel === "direct-to-pay-flow-3"
      ) {
        navigate(routes.DIRECT_TO_PAY_MEET_TUTORS.url);
      } else {
        navigate(routes.DIRECT_TO_PAY_WELCOME_B.url + "/payment-plans");
      }
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 409) {
          toast.error("User with email already exists. Please log in", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error("An error occured", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }
  };

  return { handleSignup };
}
