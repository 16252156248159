import clsx from "clsx";
import { useState } from "react";
import { CgProfile } from "react-icons/cg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";
import Logout from "src/views/Authentication/Logout";

export default function NavMenu() {
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrograms, setShowPrograms] = useState(false);
  // const handleLogout = () => {
  //   window.open(`${process.env.REACT_APP_SERVER_URL}/logout`, "_self");
  // };

  const handleNavigateToProfile = () => {
    if (user.type === "student") {
      if (!user?.phone_number) {
        navigate(routes.STUDENT_DASHBOARD_BIO.url);
      } else {
        navigate(routes.STUDENT_DASHBOARD_UPCOMING_LESSONS.url);
      }
    }
    if (user.type === "tutor") {
      navigate(routes.TUTOR_DASHBOARD_UPCOMING_LESSONS.url);
    }
  };

  return (
    <nav className="flex items-center">
      <ul
        className="flex flex-col items-center lg:flex-row flex-1 
          gap-8 font-medium "
      >
        {/* <NavLink to={routes.FIND_A_TUTOR.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-primary text-sm font-semibold hover:text-secondary":
                  routes.FIND_A_TUTOR.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-sm font-semibold hover:text-secondary":
                  routes.FIND_A_TUTOR.url === location.pathname,
              }
            )}
          >
            {routes.FIND_A_TUTOR.label}
          </li>
        </NavLink> */}

        {/* <NavLink to={routes.FIND_GROUP_LESSONS.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-primary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.FIND_GROUP_LESSONS.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.FIND_GROUP_LESSONS.url === location.pathname,
              }
            )}
          >
            {routes.FIND_GROUP_LESSONS.label}
          </li>
        </NavLink> */}
        {/* <NavLink to={routes.PRICES.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-primary text-sm font-semibold hover:text-secondary":
                  routes.PRICES.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-sm font-semibold hover:text-secondary":
                  routes.PRICES.url === location.pathname,
              }
            )}
          >
            {routes.PRICES.label}
          </li>
        </NavLink> */}

        <NavLink to={routes.BECOME_A_TUTOR.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-white text-center text-xl lg:text-sm font-semibold  hover:text-secondary":
                  routes.BECOME_A_TUTOR.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-center text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.BECOME_A_TUTOR.url === location.pathname,
              }
            )}
          >
            {routes.BECOME_A_TUTOR.label}
          </li>
        </NavLink>

        {/* <div className="lg:hidden">
          <ContactIcon colorVariant="dark" />
        </div> */}

        {/* <NavLink to={routes.BOOK_A_LESSON_VIA_ADMIN.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-primary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.BOOK_A_LESSON_VIA_ADMIN.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.BOOK_A_LESSON_VIA_ADMIN.url === location.pathname,
              }
            )}
          >
            {routes.BOOK_A_LESSON_VIA_ADMIN.label}
          </li>
        </NavLink> */}

        {/* <NavLink to={routes.SERVICES.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-primary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.SERVICES.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.SERVICES.url === location.pathname,
              }
            )}
          >
            {routes.SERVICES.label}
          </li>
        </NavLink> */}

        <NavLink to={routes.ACADEMIC.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-primary text-center text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.ACADEMIC.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-center text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.ACADEMIC.url === location.pathname,
              }
            )}
          >
            {routes.ACADEMIC.label}
          </li>
        </NavLink>
        {/* <NavLink to={routes.ABOUT.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-primary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.ABOUT.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.ABOUT.url === location.pathname,
              }
            )}
          >
            {routes.ABOUT.label}
          </li>
        </NavLink> */}
        {/* learn more */}
        <div
          onMouseOver={() => {
            setShowPrograms(true);
          }}
          onMouseOut={() => {
            setShowPrograms(false);
          }}
          className="cursor-pointer flex items-center relative text-center gap-2 text-primary text-xl lg:text-sm font-semibold hover:text-secondary"
        >
          Learn More
          <div className="border-[0.4rem] mt-[0.4rem] border-l-transparent border-r-transparent border-b-transparent border-primary"></div>
          <div
            className={`${
              showPrograms ? "opacity-100 visible" : "invisible opacity-0"
            } bg-white w-max rounded-2xl px-8 py-4 shadow-xl flex  flex-col items-center  transition-all duration-300 gap-3  absolute top-8 lg:top-5 left-0 right-0`}
          >
            <NavLink to={routes.ABOUT.url}>
              <li
                className={clsx(
                  {
                    "cursor-pointer text-primary text-xl text-center lg:text-sm font-semibold hover:text-secondary":
                      routes.ABOUT.url !== location.pathname,
                  },
                  {
                    "cursor-pointer text-secondary text-xl text-center lg:text-sm font-semibold hover:text-secondary":
                      routes.ABOUT.url === location.pathname,
                  }
                )}
              >
                {routes.ABOUT.label}
              </li>
            </NavLink>

            <NavLink to={routes.CONTACT.url}>
              <li
                className={clsx(
                  {
                    "cursor-pointer text-primary text-center text-xl lg:text-sm font-semibold hover:text-secondary":
                      routes.CONTACT.url !== location.pathname,
                  },
                  {
                    "cursor-pointer text-secondary text-center text-xl lg:text-sm font-semibold hover:text-secondary":
                      routes.CONTACT.url === location.pathname,
                  }
                )}
              >
                {routes.CONTACT.label}
              </li>
            </NavLink>
            <NavLink to={routes.FAQS.url}>
              <li
                className={clsx(
                  {
                    "cursor-pointer text-primary text-center text-xl lg:text-sm font-semibold hover:text-secondary":
                      routes.FAQS.url !== location.pathname,
                  },
                  {
                    "cursor-pointer text-secondary text-center text-xl lg:text-sm font-semibold hover:text-secondary":
                      routes.FAQS.url === location.pathname,
                  }
                )}
              >
                {routes.FAQS.label}
              </li>
            </NavLink>
          </div>
        </div>
        {/* <NavLink to={routes.CONTACT.url}>
          <li
            className={clsx(
              {
                "cursor-pointer text-primary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.CONTACT.url !== location.pathname,
              },
              {
                "cursor-pointer text-secondary text-xl lg:text-sm font-semibold hover:text-secondary":
                  routes.CONTACT.url === location.pathname,
              }
            )}
          >
            {routes.CONTACT.label}
          </li>
        </NavLink> */}
        {user && (
          <>
            <div
              className="text-primary flex gap-3 items-center lg:hidden hover:text-primary-medium cursor-pointer pb-2"
              onClick={handleNavigateToProfile}
            >
              <CgProfile />
              <h3 className="text-sm font-semibold ">My Dashboard</h3>
            </div>
            <Logout />
          </>
        )}
      </ul>
    </nav>
  );
}
