import { useContext, useEffect } from "react";
import InfoWrapper from "src/components/DirectToPay/CustomerInfo/InfoWrapper";
import FixedHeaderWithoutNav from "src/components/Shared/FixedHeaderWithoutNav";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
export default function CustomerInfo() {
  const {
    state: { subject, source },
    actions: { setDirectPayUser, setLeadFunnel },
  } = useContext(DirectPayContext);

  if (!subject) {
    window.location.href = "https://book.lingawa.com/";
  }
  useEffect(() => {
    if (source === "landing") {
      setLeadFunnel("direct-to-pay-flow-3");
    } else {
      setLeadFunnel("direct-to-pay-flow-1");
    }
    setDirectPayUser(true);

    // return () => {
    //   setDirectPayUser(false);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <FixedHeaderWithoutNav />
      <div className="text-primary  relative min-h-screen h-[full]">
        <div className="">
          <InfoWrapper />
        </div>
      </div>
    </>
  );
}
