import { useNavigate } from "react-router-dom";

const logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-christmas-logo.png`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-christmas-logo.png`;

export default function Logo() {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="relative lg:flex items-center h-10 
            cursor-pointer my-auto hidden ml-4"
        onClick={() => navigate("/")}
      >
        <img src={logo} alt="" width={220} />
      </div>
      <div
        className="relative flex items-center h-10 
            cursor-pointer my-auto lg:hidden"
        onClick={() => navigate("/")}
      >
        <img src={mobile_logo} alt="" width={160} />
      </div>
    </div>
  );
}
