import React, { ReactNode } from "react";
import CardContainer from "./CardContainer";
import clsx from "clsx";

interface IGroup {
  children: ReactNode;
  title: string;
  completed?: string;
}
export default function Group({ title, completed, children }: IGroup) {
  console.log("Group.tsx: Group: title: ", title);
  console.log("Group.tsx: Group: completed: ", completed);
  console.log("Group.tsx: Group: children: ", children);

  return (
    <section>
      <div className="flex flex-col sm:flex-row sm:items-center items-start justify-between gap-1">
        <p className=" text-xl font-semibold">{title} Lessons</p>
        {completed && (
          <p
            className={clsx({
              "bg-gradient-to-br from-lingawa-teal to-lingawa-teal-dark text-white rounded-lg py-[13.5px] px-[22px] font-semibold text-xl leading-6 ":
                title === "Beginner",
              "bg-gradient-to-br from-lingawa-orange to-orange-700 text-white rounded-lg py-[13.5px] px-[22px] font-semibold text-xl leading-6 ":
                title === "Elementary",
              "bg-gradient-to-br from-lingawa-primary to-primary text-white rounded-lg py-[13.5px] px-[22px] font-semibold text-xl leading-6 ":
                title === "Intermediate",
            })}
          >
            {Number(completed) === 100
              ? "Completed!"
              : completed + "% Unlocked"}
          </p>
        )}
      </div>
      <CardContainer>{children}</CardContainer>
    </section>
  );
}
