import axios from "axios";
import clsx from "clsx";
import { Formik } from "formik";
import { useContext, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";
import * as Yup from "yup";

interface LocationState {
  from: {
    pathname: string;
  };
}

export default function LoginForm() {
  const [{}, dispatch] = useStateValue();
  const [loginError, setLoginError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const {
    actions: { getOnboarding },
  } = useContext(DirectPayContext);
  const location = useLocation();
  const state = location.state as LocationState;
  const from = state?.from?.pathname || "/";
  const { tutorName, tutorId } = useParams();

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div>
      {" "}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string()
            .max(255)
            .min(8)
            .required("Password is required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setLoginError("");
          setSubmitting(true);

          try {
            axios({
              method: "POST",
              data: values,
              withCredentials: true,
              url: `${process.env.REACT_APP_SERVER_URL}/auth/local-login`,
            }).then(async (res) => {
              if (res.data.user) {
                localStorage.setItem("accessToken", res.data.accessToken);

                try {
                  await getOnboarding(res.data.user._id);
                  dispatch({
                    type: "SET_USER",
                    user: res.data.user,
                  });

                  return;
                } catch (error) {
                  console.log(error);
                }
                dispatch({
                  type: "SET_USER",
                  user: res.data.user,
                });
                if (state?.from?.pathname) {
                  setTimeout(() => {
                    navigate(from);
                  }, 200);
                  return;
                }
                if (res.data.user.onboardingStatus === 0) {
                  navigate("/tutor-onboarding-0");
                  return;
                }
                if (location.pathname.includes(routes.VIEW_TUTOR_PROFILE.url)) {
                  navigate(
                    `${routes.BOOK_A_LESSON.url}/${tutorName}/${tutorId}`
                  );
                  return;
                }
              }

              if (!res.data.user) {
                if (res.data.disabled) {
                  setLoginError(res.data.message);
                } else {
                  setLoginError("Invalid login details");
                }
              }
              setSubmitting(false);
            });
          } catch (error) {
            console.log("caught error: ", error);
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div>
              <label className="block mb-2 text-primary text-sm font-medium">
                Email Address
              </label>
              <input
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                placeholder="Email Address"
                name="email"
                value={values.email}
              />
              <p
                className={clsx({
                  "text-red-500 text-xs mt-1": touched.email && errors.email,
                  hidden: !(touched.email && errors.email),
                })}
              >
                {errors.email}
              </p>
            </div>

            <div className="mt-4 relative">
              <label className="block mb-2 text-primary text-sm font-medium">
                Password
              </label>

              <div className="relative">
                <input
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-blue-500 focus:outline-none focus:ring"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Password"
                  type={isPasswordVisible ? "text" : "password"}
                  name="password"
                  value={values.password}
                />
                <div className="absolute right-4 top-3">
                  {" "}
                  {isPasswordVisible ? (
                    <AiFillEyeInvisible
                      className="cursor-pointer text-primary-medium"
                      size={23}
                      onClick={handleTogglePasswordVisibility}
                    />
                  ) : (
                    <AiFillEye
                      className="cursor-pointer text-primary-medium"
                      size={23}
                      onClick={handleTogglePasswordVisibility}
                    />
                  )}
                </div>
              </div>
              <p
                className={clsx({
                  "text-red-500 text-xs mt-1":
                    touched.password && errors.password,
                  hidden: !(touched.password && errors.password),
                })}
              >
                {errors.password}
              </p>
            </div>

            <div className="mt-6">
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex gap-4 bg-lingawa-primary items-center justify-center 
                            p-4 rounded-lg cursor-pointer border-2 hover:bg-primary-light w-full"
              >
                <h3 className="text-white font-semibold md:text-sm">
                  Log in with email
                </h3>
              </button>
              <p className="text-red-600 text-xs mt-2">{loginError}</p>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
