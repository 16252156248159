// WrittenAnswerPrompt.tsx
import React, { useState, useEffect } from "react";
import { Prompt } from "./types";

interface WrittenAnswerPromptProps {
  prompt: Prompt;
  beastMode: boolean;
  onBeastModeToggle: () => void;
  onNext: (isCorrect: boolean) => void;
}

const WrittenAnswerPrompt: React.FC<WrittenAnswerPromptProps> = ({
  prompt,
  beastMode,
  onBeastModeToggle,
  onNext,
}) => {
  const [userAnswer, setUserAnswer] = useState<string>("");
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  useEffect(() => {
    setUserAnswer("");
    setHasSubmitted(false); // Reset when new prompt arrives
  }, [prompt._id]);

  // Function to normalize user input
  const normalizeInput = (input: string) => {
    return input
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[\s.,/#!$%^&*;:{}=_`~()]/g, "")
      .toLowerCase();
  };

  const handleSubmit = () => {
    if (hasSubmitted) return; // Prevent double submission
    setHasSubmitted(true);

    const normalizedUserAnswer = normalizeInput(userAnswer);
    let isCorrect = false;

    if (beastMode) {
      // Strict mode: exact match except for case differences
      const lowerUserAnswer = userAnswer.trim().toLowerCase();
      isCorrect =
        prompt.strictModeAnswers?.some(
          (syn) => syn.toLowerCase() === lowerUserAnswer
        ) || false;
    } else {
      // Non-strict mode: match with alternateAnswers (normalized)
      const normalizedAlternates =
        prompt.alternateAnswers?.map((ans) => normalizeInput(ans)) || [];
      const normalizedStrictAnswers =
        prompt.strictModeAnswers?.map((ans) => normalizeInput(ans)) || [];

      isCorrect =
        normalizedAlternates.includes(normalizedUserAnswer) ||
        normalizedStrictAnswers.includes(normalizedUserAnswer);
    }

    // Proceed to the next prompt after a delay
    setTimeout(() => {
      onNext(isCorrect);
    }, 1000);
  };

  // Clear the input field when the prompt changes
  useEffect(() => {
    setUserAnswer("");
  }, [prompt._id]);

  console.log("hasSubmitted", hasSubmitted);

  return (
    <div
      className={`
         mx-auto p-6 rounded-lg shadow-md transition-transform transform 
        ${
          beastMode
            ? "bg-gradient-to-br from-orange-500 to-orange-800"
            : "bg-gradient-to-br from-lingawa-primary to-primary"
        }
      `}
    >
      {/* Header Section with Toggle */}
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          {/* Playful character / emoji */}
          <span className="text-3xl select-none">
            {beastMode ? "🦁" : "🦉"}
          </span>
          <h2 className="text-xs font-bold text-white">
            {beastMode ? "Beast Mode" : "Normal Mode"}
          </h2>
        </div>

        {/* Beast Mode Toggle */}
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={beastMode}
            onChange={onBeastModeToggle}
          />
          <div
            className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-lingawa-teal dark:peer-focus:ring-lingawa-teal dark:bg-gray-500 relative after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:border-gray-300 after:rounded-full after:h-4 after:w-4 after:transition-all 
            peer-checked:bg-lingawa-primary peer-checked:after:translate-x-full peer-checked:after:border-white"
          />
        </label>
      </div>

      <p className="text-gray-300 md:text-2xl text-center mb-4">
        Translate:{" "}
        <span className="font-semibold text-white">
          {prompt.word.englishTranslation}
        </span>
      </p>

      {/* Display image if available */}
      {prompt.word.imageUrl && (
        <div className="flex justify-center mb-4">
          <img
            src={prompt.word.imageUrl}
            alt={prompt.word.englishTranslation}
            className="max-h-40 object-contain rounded shadow-md"
          />
        </div>
      )}

      <div className="flex flex-col items-center">
        <input
          type="text"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          placeholder="Type your answer here..."
          className={`
            w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 
            ${beastMode ? "focus:ring-lingawa-teal" : "focus:ring-orange-500"}
          `}
        />

        <button
          onClick={handleSubmit}
          disabled={!userAnswer || hasSubmitted}
          className={`
            py-2 px-4 rounded-xl font-medium transition-transform transform hover:scale-110 
            ${
              userAnswer && !hasSubmitted
                ? beastMode
                  ? "bg-lingawa-teal text-white"
                  : "bg-lingawa-teal text-white "
                : "bg-gray-200 text-white cursor-not-allowed"
            }
          `}
        >
          Submit
        </button>
      </div>

      {/* Encouraging message at the bottom */}
      <div className="mt-4 text-center text-white text-sm italic">
        {beastMode
          ? "Only perfect answers — no mercy on typos or accents! 🦾"
          : "Stay calm and confident. You've got this! ✨"}
      </div>
    </div>
  );
};

export default WrittenAnswerPrompt;
