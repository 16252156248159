// FeedbackPopup.tsx
import React, { useEffect } from "react";

interface FeedbackPopupProps {
  feedbackType: "correct" | "incorrect";
  onClose: () => void;
  currentPrompt: any;
}

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({
  feedbackType,
  onClose,
  currentPrompt,
}) => {
  useEffect(() => {
    // Play audio based on feedbackType
    const audio = new Audio(
      feedbackType === "correct"
        ? `${process.env.REACT_APP_ASSET_CDN}/success.wav`
        : `${process.env.REACT_APP_ASSET_CDN}/error.mp3`
    );
    audio.play();

    // Automatically close after 2 seconds
    const timer = setTimeout(() => {
      onClose();
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [feedbackType, onClose]);

  return (
    <div className="fixed bottom-4 md:left-60 left-0 right-0 flex justify-center">
      <div
        className={`px-6 py-4 rounded-lg shadow-lg transform transition-all duration-500 ${
          feedbackType === "correct"
            ? "bg-green-500 text-white animate-bounce"
            : "bg-red-500 text-white animate-shake"
        }`}
      >
        {feedbackType === "correct" ? (
          <div className="flex items-center space-x-2">
            <span className="text-2xl">🎉</span>
            <span className="text-xl font-bold">{currentPrompt.word.word}</span>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <span className="text-2xl">😢</span>
            <span className="text-xl font-bold">{currentPrompt.word.word}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackPopup;
