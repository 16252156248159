import { XIcon, MenuIcon } from "@heroicons/react/solid";
import { useContext, useState } from "react";
import clsx from "clsx";

import { useNavigate } from "react-router-dom";
import { useStateValue } from "src/context/StateProvider";
import UserProfile from "src/components/Shared/Header/UserProfile";
import MobileNav from "../DashboardMobileNav";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
const logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-christmas-logo.png`;
const mobile_logo = `${process.env.REACT_APP_ASSET_CDN}/lingawa-christmas-logo.png`;
const bannerBackground = `${process.env.REACT_APP_ASSET_CDN}/blackfriday-bannr.png`;

function Header() {
  const navigate = useNavigate();
  const [{ learningAppLoading, showMobileMenu }, dispatch] = useStateValue();

  const {
    state: { onBoarding },
  } = useContext(DirectPayContext);
  console.log(onBoarding.onboarding?.leadFunnel);
  const toggleMenu = async () => {
    await dispatch({
      type: "SET_SHOW_MOBILE_MENU",
      showMobileMenu: !showMobileMenu,
    });
  };

  const onSwitchToLearningClick = async () => {
    await dispatch({
      type: "SET_LEARNING_APP_LOADING",
      learningAppLoading: true,
    });
    navigate("/");
  };

  return (
    <div className="top-0 z-50  sticky">
      {/* <div
        className="
   py-2  flex justify-around"
        style={{
          backgroundImage: `url(${bannerBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="text-white">
          It's <span className="font-semibold  text-lg">Black Friday </span>
          season!!! Enjoy up to{" "}
          <span className="font-semibold text-lg">50% OFF! </span>
        </p>
      </div> */}
      <header
        className="
    bg-lingawa-primary py-5  border-b-2"
      >
        {/* Top Bar */}
        <div className="flex justify-between px-6">
          {/* left */}
          <div>
            <div
              className="lg:flex items-center h-10 
            cursor-pointer hidden"
              onClick={() => {
                onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-1"
                  ? navigate("/welcome/a/get-started")
                  : onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-2"
                  ? navigate("/welcome/b/get-started")
                  : onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-3"
                  ? navigate("/welcome/c/get-started")
                  : navigate("/welcome/c/get-started");
              }}
            >
              <img src={logo} alt="" width={171} height={135} />
            </div>
            <div
              className="relative flex items-center h-10 
            cursor-pointer my-auto lg:hidden"
              onClick={() => {
                onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-1"
                  ? navigate("/welcome/a/get-started")
                  : onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-2"
                  ? navigate("/welcome/b/get-started")
                  : onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-3"
                  ? navigate("/welcome/c/get-started")
                  : navigate("/welcome/c/get-started");
              }}
            >
              <img src={mobile_logo} alt="" width={160} />
            </div>
          </div>

          {/* right */}
          {/* Logged In Profile  */}
          <div>
            <div className="lg:flex items-center space-x-6 hidden">
              {/* <div>
              <p
                className="text-sm text-primary hover:text-secondary cursor-pointer font-semibold"
                onClick={onSwitchToLearningClick}
              >
                Switch to Learning
              </p>
            </div> */}
              <UserProfile />
            </div>
          </div>
          {/* Burger Icon */}
          <div className="flex lg:hidden">
            <div
              className={clsx({
                block: !showMobileMenu,
                hidden: showMobileMenu,
              })}
            >
              <MenuIcon
                onClick={toggleMenu}
                className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
              />
            </div>
            <div
              className={clsx({
                block: showMobileMenu,
                hidden: !showMobileMenu,
              })}
            >
              <XIcon
                onClick={toggleMenu}
                className="inline-flex h-10 w-10 text-black lg:hidden cursor-pointer"
              />
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div
          className={clsx({
            block: showMobileMenu,
            hidden: !showMobileMenu,
          })}
        >
          <div className="w-full px-6 bg-pale-purple-bg absolute lg:hidden h-screen">
            <MobileNav />
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
