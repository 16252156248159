import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "src/components/StudentDashboard/Learn/Breadcrumb";
import { useStateValue } from "src/context/StateProvider";
import { useParams } from "react-router-dom";
import {
  LessonResources,
  lessonResources,
} from "src/static-data/lessonResources";
import Resources from "src/components/StudentDashboard/Learn/Resources";
import EmptyList from "src/components/Shared/EmptyList";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import { PiCardsFill } from "react-icons/pi";
import { FaQuora } from "react-icons/fa";
import { FaPhotoVideo } from "react-icons/fa";
import { FaGamepad } from "react-icons/fa6";
import { PiExamFill } from "react-icons/pi";

const practiceSessions = [
  {
    id: 1,
    title: "Grammar Spotlight",
    icon: <FaStar className="text-lingawa-gold" size={25} />,
    description:
      "Review a comprehensive list of words and phrases from your lessons.",
  },
  {
    id: 2,
    title: "Quizlet Sets",
    icon: <FaQuora className="text-lingawa-primary" size={25} />,
    description:
      "Practice your words on Greetings with your lesson quizlet FlashCards!",
  },
  {
    id: 3,
    title: "Practice Video",
    icon: <FaPhotoVideo className="text-lingawa-teal" size={25} />,
    description:
      "Revise your conversation practice video to get more fluent in holding conversations about greetings.",
  },
  {
    id: 5,
    title: "Flashcards",
    icon: <PiCardsFill className="text-lingawa-orange" size={25} />,
    description:
      "Flashcards are a great way to learn new words and phrases. Use the flashcards to learn new words and phrases.",
  },
  {
    id: 7,
    title: "Test Your Knowledge",
    icon: <PiExamFill className="text-purple-500" size={25} />,
    description:
      "Test your knowledge on the words and phrases you’ve learnt in this lesson.",
  },
  {
    id: 4,
    title: "Sentence Construction Exercises",
    icon: <FaGamepad className="text-pink-500" size={25} />,
    description:
      "Play the sentence constructor game to construct different sentences with  the words you’ve learnt!",
  },
  {
    id: 6,
    title: "Prerequisites",
    icon: <FaExternalLinkAlt className="text-blue-500" size={25} />,
    description:
      "Review the key topics and skills you should be familiar with before starting this session!",
  },
];

export default function TopicDetails() {
  const [{ userLessonProgress }] = useStateValue();
  const { userId, level, topic, topicId } = useParams();
  const [hasSessions, setHasSessions] = useState(false);
  const [quizletSet, setQuizletSet] = useState<string | null>(null);
  const [grammarSpotlight, setGrammarSpotlight] = useState<string | null>(null);
  const [practiceVideo, setPracticeVideo] = useState<any>(null);
  const prerequisites = userLessonProgress?.unordered?.find(
    (t: any) => t?.topicId === topicId
  )?.prerequisites;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!userLessonProgress) return;
    // Check if any sessions are rendered
    const sessionsExist = practiceSessions.some((session) => {
      const quizlet = (lessonResources as LessonResources)?.[
        userLessonProgress?.curriculum
      ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
        ?.resources.quizletSet;
      const grammarSpotlight = (lessonResources as LessonResources)?.[
        userLessonProgress?.curriculum
      ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
        ?.resources.grammarSpotlight;
      const videoIframe = (lessonResources as LessonResources)?.[
        userLessonProgress?.curriculum
      ]?.[userLessonProgress.ageGroup]?.[level as any]?.[topic as any]
        ?.resources.practiceVideo;
      console.log(session.id);

      if (grammarSpotlight) {
        setGrammarSpotlight(grammarSpotlight);
      }
      if (quizlet) {
        setQuizletSet(quizlet);
      }
      if (videoIframe) {
        setPracticeVideo(videoIframe);
      }

      return (
        (session.id === 1 && grammarSpotlight) ||
        (session.id === 2 && quizlet) ||
        (session.id === 3 && videoIframe) ||
        session.id === 4
      );
    });

    setHasSessions(sessionsExist);
  }, [userLessonProgress, level, topic]);

  return (
    <section className="text-primary">
      <Breadcrumb />
      <div className="grid mt-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {practiceSessions.map((session) => {
          if (session.id === 1 && grammarSpotlight) {
            return (
              <div className="mb-4">
                <Resources
                  id={session.id}
                  key={session.id}
                  title={session.title}
                  icon={session.icon}
                  description={session.description}
                  link={grammarSpotlight}
                />
              </div>
            );
          }

          if (session.id === 2 && quizletSet) {
            return (
              <div className="mb-4">
                <Resources
                  id={session.id}
                  key={session.id}
                  title={session.title}
                  icon={session.icon}
                  description={session.description}
                  link={quizletSet}
                />
              </div>
            );
          }
          if (session.id === 3 && practiceVideo) {
            return (
              <div className="mb-4">
                <Resources
                  id={session.id}
                  key={session.id}
                  title={session.title}
                  icon={session.icon}
                  description={session.description}
                />
              </div>
            );
          }
          if (session.id === 5) {
            return (
              <div className="mb-4">
                <Resources
                  id={session.id}
                  key={session.id}
                  title={session.title}
                  icon={session.icon}
                  description={session.description}
                />
              </div>
            );
          }
          if (session.id === 7) {
            return (
              <div className="mb-4">
                <Resources
                  id={session.id}
                  key={session.id}
                  title={session.title}
                  icon={session.icon}
                  description={session.description}
                />
              </div>
            );
          }
          if (session.id === 4) {
            return (
              <div className="mb-4">
                <Resources
                  id={session.id}
                  key={session.id}
                  title={session.title}
                  icon={session.icon}
                  description={session.description}
                />
              </div>
            );
          }
          if (session.id === 6 && prerequisites && prerequisites.length > 0) {
            return (
              <div className="mb-4">
                <Resources
                  id={session.id}
                  key={session.id}
                  title={session.title}
                  icon={session.icon}
                  description={session.description}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
      {!hasSessions && (
        <EmptyList message="No resources available for this topic" />
      )}
    </section>
  );
}
