import { useEffect } from "react";

const RedirectToNewDomain = () => {
  useEffect(() => {
    const { hostname, pathname, search, hash } = window.location;

    if (hostname === "learn.topset.app") {
      const newUrl = `https://learn.lingawa.com${pathname}${search}${hash}`;
      window.location.replace(newUrl);
    } else if (hostname === "topset-dev.vercel.app") {
      const newUrl = `https://lingawa-dev.vercel.app${pathname}${search}${hash}`;
      window.location.replace(newUrl);
    }
  }, []);

  return null; // Since this component doesn't render anything
};

export default RedirectToNewDomain;
