import { BsFillEnvelopeFill } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import ContactIcon from "./Header/UserProfile/ContactIcon";
import { routes } from "src/Routes";
import ContactAddress from "./ContactAddress";

export default function Footer() {
  const date = new Date();
  const dateYear = date.getFullYear();

  return (
    <div className="bg-primary">
      <div className="flex flex-col mt-32 text-white text-sm font-extralight py-12 justify-center items-center">
        <div className="mb-3 w-96"></div>
        <div>{`Lingawa © ${dateYear} All Rights Reserved`}</div>
        <div className="flex text-xs mt-4 gap-4">
          {/* link to privacy policy page */}
          <a href={routes.PRIVACY_POLICY.url}>Privacy Policy</a>
          <p>|</p>
          <a href={routes.COOKIE_POLICY.url}>Cookie Policy</a>
          <p>|</p>
          <a href={routes.FAQS.url}>FAQs</a>
        </div>
        <div className="mt-4">
          <ContactIcon colorVariant="light" />
        </div>
        <div className="mt-4">
          <ContactAddress colorVariant="light" />
        </div>
        <div className="flex text-xs mt-4 gap-4">
          <a
            className="cursor-pointer hover:text-secondary"
            href="mailto:info@lingawa.com"
          >
            <BsFillEnvelopeFill size={25} />
          </a>
          <a
            className="cursor-pointer hover:text-secondary"
            href="https://www.instagram.com/topsettutoring/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiInstagram size={23} />
          </a>
          <a
            className="cursor-pointer hover:text-secondary"
            href="https://www.linkedin.com/company/topsettutoring"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={22} />
          </a>
          <a
            className="cursor-pointer hover:text-secondary"
            href="https://www.facebook.com/topsettutoring.africagit/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={20} />
          </a>
        </div>
      </div>
    </div>
  );
}
