import React, { useEffect } from "react";
import Confetti from "react-confetti";

interface CheckpointProps {
  currentScore: number;
  questionsAttempted: number;
  onContinue: () => void;
}

const Checkpoint: React.FC<CheckpointProps> = ({
  currentScore,
  questionsAttempted,
  onContinue,
}) => {
  useEffect(() => {
    // Play a celebratory sound
    const audio = new Audio(
      `${process.env.REACT_APP_ASSET_CDN}/checkpoint.mp3`
    );
    audio.play();
  }, []);

  // Calculate user's current progress percentage
  const progressPercentage = Math.round(
    (currentScore / questionsAttempted) * 100
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <Confetti />
      <div className="bg-gradient-to-br from-orange-500 to-lingawa-teal p-8 rounded-xl shadow-xl max-w-md w-full text-center animate-bounce-in">
        <h2 className="text-2xl font-bold mb-4 text-white">
          🎯 Checkpoint Reached!
        </h2>
        <p className="text-white text-lg mb-4">
          You've completed a stage of the quiz.
        </p>
        <p className="text-xl text-lingawa-primary font-semibold mb-4">
          Current Score: {currentScore}/ {questionsAttempted}
        </p>
        <p className="text-white text-lg mb-6">
          That's {progressPercentage}% correct so far! Keep it up!
        </p>
        <button
          onClick={onContinue}
          className="bg-orange-600 text-white font-medium px-6 py-2 rounded hover:bg-orange-700 transition"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Checkpoint;
