import React, { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  Row,
  Column,
} from "react-table";
import clsx from "clsx";

import "tippy.js/dist/tippy.css";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { COLUMNS } from "./column";
import { GlobalFilter } from "../../StudentLessons/UpcomingLessonsViaAdmin/RequestsTable/GlobalFilter";
import { Pagination } from "../../StudentLessons/UpcomingLessonsViaAdmin/RequestsTable/Pagination";

toast.configure();
const orders = [
  {
    id: "f0d685f4-07ab-4eff-ba80-5811303c607d",
    type: "PAYMENT",
    state: "PENDING",
    created_at: "2021-02-10T18:17:37.959383Z",
    updated_at: "2021-02-10T18:17:37.959383Z",
    order_amount: {
      value: 35000,
      currency: "GBP",
    },
    order_outstanding_amount: {
      value: 35000,
      currency: "GBP",
    },
  },
  {
    id: "feca684a-b9ea-4033-9bc4-b9e6ac12ada6",
    type: "PAYMENT",
    state: "COMPLETED",
    created_at: "2021-02-10T16:59:23.642673Z",
    updated_at: "2021-02-10T16:59:50.886826Z",
    completed_at: "2021-02-10T16:59:50.886826Z",
    settlement_currency: "USD",
    email: "sally.gibson@lloydsbank.co.uk",
    order_amount: {
      value: 1000,
      currency: "GBP",
    },
    order_outstanding_amount: {
      value: 0,
      currency: "GBP",
    },
  },
  {
    id: "f3c5e3f1-f73a-4853-a9e3-b0261301c66a",
    type: "PAYMENT",
    state: "FAILED",
    created_at: "2021-02-10T16:58:47.507560Z",
    updated_at: "2021-02-10T16:59:52.847017Z",
    completed_at: "2021-02-10T16:59:52.847017Z",
    description: "URBAN 'Panther' Watch by José Almeida",
    capture_mode: "AUTOMATIC",
    merchant_order_ext_ref: "testorder123",
    customer_id: "31345442-3d03-4c4b-8354-3bdaf0ca9600",
    email: "someothermail@gmail.com",
    order_amount: {
      value: 777,
      currency: "GBP",
    },
    order_outstanding_amount: {
      value: 0,
      currency: "GBP",
    },
  },
];

export default function RequestsTable({ payments }: any) {
  const navigate = useNavigate();

  const columns = useMemo<any>(() => COLUMNS, []);
  const data = useMemo(() => payments, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    pageOptions,
    state,
    prepareRow,
    // @ts-ignore
    setGlobalFilter,
  } = tableInstance;

  // @ts-ignore
  const { globalFilter, pageIndex } = state;

  return (
    <>
      <div>
        <div className="lg:flex gap-3 items-center justify-between mb-4">
          <div className="flex items-center gap-4">
            <p className="text-primary-light font-semibold">Search:</p>
            <div className="flex-row-end">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
          </div>
        </div>
        <div className="white-card-rounded">
          <table className="border-collapse w-full" {...getTableProps()}>
            <thead className="text-left text-sm text-white bg-light-purple-light">
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="flex gap-2 sm:table-row "
                >
                  {headerGroup.headers.map((column) =>
                    column.id === "_id" ? (
                      <th
                        className="py-4  pl-10 table-cell rounded-tl-2xl "
                        {...column.getHeaderProps()}
                        style={{ width: "33.33%" }}
                      >
                        {column.render("Header")}
                      </th>
                    ) : column.id === "amount" ? (
                      <th
                        className="py-4 pr-10 text-pale-purple-bg-light rounded-tr-2xl"
                        {...column.getHeaderProps()}
                        style={{ width: "33.33%" }}
                      >
                        {column.render("Header")}
                      </th>
                    ) : (
                      <th
                        className="py-4  "
                        {...column.getHeaderProps()}
                        style={{ width: "33.33%" }}
                      >
                        {column.render("Header")}
                      </th>
                    )
                  )}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row: Row<any>, i: any) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={clsx({
                      "flex flex-wrap gap-2 sm:table-row justify-center items-center px-4":
                        row.index === 0,
                      "flex flex-wrap gap-2 sm:table-row justify-center items-center border-t-[1px] border-gray-200 px-4 ":
                        row.index !== 0,
                    })}
                  >
                    {row.cells.map(
                      (cell: {
                        row: any;
                        column: { id: string };
                        getCellProps: () => JSX.IntrinsicAttributes &
                          React.ClassAttributes<HTMLTableDataCellElement> &
                          React.TdHTMLAttributes<HTMLTableDataCellElement>;
                        value: string | undefined;
                        render: (arg0: string) => {} | null | undefined;
                      }) =>
                        cell.column.id === "_id" ? (
                          <td
                            className="py-2 pl-10 border-t-[1px] table-cell text-sm text-primary pr-4"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        ) : cell.column.id === "state" ? (
                          <td
                            className="py-6 border-t-[1px] pr-8 w-36 text-center  text-sm "
                            {...cell.getCellProps()}
                          >
                            <p
                              className={clsx({
                                "bg-red-100 rounded-2xl px-3 py-1 uppercase text-xs font-semibold text-red-500":
                                  cell.value === "FAILED",
                                "bg-green-100 rounded-2xl px-3 py-1 uppercase text-xs font-semibold text-green-500":
                                  cell.value === "COMPLETED",
                                "bg-orange-100 rounded-2xl px-3 py-1 uppercase text-xs font-semibold text-orange-500":
                                  cell.value === "PENDING",
                              })}
                            >
                              {cell.render("Cell")}
                            </p>
                          </td>
                        ) : (
                          <td
                            className="py-6 sm:border-t-[1px] table-cell text-sm text-primary"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-4">
          <Pagination
            nextPage={nextPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
          />
        </div>
      </div>
    </>
  );
}
