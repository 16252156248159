import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LearningAppApi } from "src/api/learning-app";
import LoadingBarScreen from "src/components/LottieAnimations/LoadingBarScreen";
import EmptyList from "src/components/Shared/EmptyList";
import { useStateValue } from "src/context/StateProvider";
import { routes } from "src/Routes";
import BackNavigation from "../BackNavigation";

export default function SentenceContructionSelection() {
  const [{ user }] = useStateValue();
  const navigate = useNavigate();
  const { order, topicId, userId, dependantId, dependantName } = useParams();
  const [interactiveSessions, setInteractiveSessions] = useState<any>([]);
  const [topic, setTopic] = useState<any>();
  const [level, setLevel] = useState<any>();
  const [userProgress, setUserProgress] = useState<any>();
  const [isComponentLoading, setIsComponentLoading] = useState(true);

  let route = dependantId
    ? `${routes.DEPENDANT_DASHBOARD_LEARN.url}/${dependantName}/${dependantId}`
    : routes.STUDENT_DASHBOARD_LEARN.url;

  useEffect(() => {
    if (!topicId) return;
    LearningAppApi.getInteractiveSessions(topicId, userId!).then((response) => {
      setInteractiveSessions(response?.data?.sessions);
      setTopic(response?.data?.topic?.title);
      setLevel(response?.data?.level?.title);
      setUserProgress(response?.data?.userProgress);
      setIsComponentLoading(false);
    });
  }, [topicId]);

  console.log("sentence construction selection");
  console.log("interactiveSessions", interactiveSessions);
  console.log("userProgress", userProgress);

  if (isComponentLoading) {
    return <LoadingBarScreen />;
  }

  if (!interactiveSessions?.length) {
    return (
      <div>
        <div>
          <BackNavigation
            navigationText="back to resources"
            isBetaVersion
            url={`${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}/${level}/${order}/${topic}/${topicId}`}
          />
        </div>
        <EmptyList message="Sentence construction coming soon for this topic" />
      </div>
    );
  }

  return (
    <div>
      <div>
        <BackNavigation
          navigationText="back to resources"
          isBetaVersion
          url={`${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}/${level}/${order}/${topic}/${topicId}`}
        />
      </div>
      <div className="flex flex-col lg:flex-row gap-4 mt-6 flex-wrap">
        {interactiveSessions?.map((session: any) => (
          <div
            key={session._id}
            className="white-card-rounded mb-4 w-full lg:w-[350px] p-4 lg:min-h-[200px] flex flex-col justify-between"
          >
            <div>
              <h2 className="text-primary font-semibold">{session.title}</h2>
              <p className="text-primary-medium text-xs">
                {session.description}
              </p>
            </div>
            <div>
              <div className="flex gap-2 mt-4">
                <img
                  src={`${process.env.REACT_APP_ASSET_CDN}/cowry_icon.png`}
                  alt=""
                />
                <p className="text-[#FD9F27] text-sm">50 Cowries</p>
              </div>
              <div className="flex mt-2">
                <button
                  className={
                    userProgress.some(
                      (obj: { sessionId: any }) => obj.sessionId === session._id
                    )
                      ? "pink-outline-button w-full"
                      : "pink-button w-full"
                  }
                  onClick={() =>
                    navigate(
                      `${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}${routes.SENTENCE_CONSTRUCTION_SESSION.url}/${session._id}`
                    )
                  }
                >
                  {userProgress.some(
                    (obj: { sessionId: any }) => obj.sessionId === session._id
                  )
                    ? "Revise Session"
                    : "Start Session"}
                </button>
                <div className="w-[100px]">
                  <img
                    src={
                      userProgress.some(
                        (obj: { sessionId: any }) =>
                          obj.sessionId === session._id
                      )
                        ? `${process.env.REACT_APP_ASSET_CDN}/medal-icon.svg`
                        : `${process.env.REACT_APP_ASSET_CDN}/medal-icon-gray.svg`
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
