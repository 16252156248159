export const lessonResources = {
  Yoruba: {
    Child: {
      Beginner: {
        "Introduction & Emotions": {
          name: "Introduction & Emotions",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/902046110/yoruba-kids-basic-emotion-flash-cards/?funnelUUID=06e5e6c0-1c13-4551-8376-6a11592ae2a4",
            practiceVideo: "",
            sentenceContruction: true,
          },
        },
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901942163/yoruba-kids-level-1-topic-1-greetings-flash-cards/",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true,
          },
        },
        Introductions: {
          name: "Introductions",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901961864/yoruba-kids-level-1-topic-2-introductions-flash-cards/?funnelUUID=7c123ca9-f7db-47bb-8163-9913266e0941",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/RiJDQnXinvM" title="How do I introduce myself in Yorùbá?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true,
          },
        },
        Manners: {
          name: "Manners",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901958598/yoruba-kids-level-1-topic-3-manners-flash-cards/?funnelUUID=d9c569a7-5889-4993-8e16-a8f96cac8bb1",
            practiceVideo:
              '<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
            sentenceContruction: true,
          },
        },
        "Core Family": {
          name: "Core Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902000481/yoruba-kids-level-1-topic-4-core-family-flash-cards/?funnelUUID=ca3fd1ce-f4b9-4c95-894e-556b8a5639d6`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1Xb90RdPSYbthjXEGa1UWaB2I1x4N6AA3/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Recap (Session 1- 4)": {
          name: "Recap (Session 1- 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/916538560/yoruba-kids-level-consolidation-topic-1-4-flash-cards/?funnelUUID=7b7f5e4d-a05d-4a31-8f25-5c709b45592d`,
            practiceVideo: ``,
          },
        },
        "Close Family": {
          name: "Close Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902175974/yoruba-kids-level-1-topic-5-close-family-flash-cards/?funnelUUID=67112c27-3789-44b4-a644-6a312a663578`,
            practiceVideo: ``,
          },
        },
        Friends: {
          name: "Friends",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902192069/yoruba-kids-level-1-topic-6-friends-flash-cards/?funnelUUID=04c4b8ea-3a34-4957-be63-c8029b922bce`,
            practiceVideo: ``,
          },
        },
        School: {
          name: "School",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902197150/yoruba-kids-level-1-topic-7-school-flash-cards/?funnelUUID=271e4e1b-e772-4dc3-bd54-bf6c19135451`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Around the House": {
          name: "Around the House",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902372950/yoruba-kids-level-1-topic-8-around-the-house-flash-cards/?funnelUUID=a7d1dbf1-f59b-4a7f-902c-9eab264ef46d`,
            practiceVideo: ``,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
                `,
            quizletSet: `https://quizlet.com/gb/916539985/yoruba-kids-level-1-consolidation-set-topic-5-8-flash-cards/?funnelUUID=66c2d2cb-83f5-4e68-b412-9ac48d423608`,
            practiceVideo: ``,
          },
        },

        Colours: {
          name: "Colours",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902375327/yoruba-kids-level-1-topic-9-colours-flash-cards/?funnelUUID=68088d10-3eca-4f5f-b1ce-384786e09b5a`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902377720/yoruba-kids-level-1-topic-10-clothing-flash-cards/?funnelUUID=9fbf80cf-f88f-4f46-8c25-102225f12db8`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/RiJDQnXinvM?si=o3APVllVl5FsRTT6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902381645/yoruba-kids-level-1-topic-11-numbers-flash-cards/?funnelUUID=e080f238-722b-464b-818f-5626dce7eb56`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Face": {
          name: "Parts of the Face",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902379633/yoruba-kids-level-1-topic-12-parts-of-the-face-flash-cards/?funnelUUID=7aa6abe8-b3af-448f-bfd7-4479e60bc3c2`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 9- 12)": {
          name: "Recap (Session 9- 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/916542214/yoruba-kids-level-1-consolidation-set-topic-9-12-flash-cards/?new`,
            practiceVideo: ``,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902558510/yoruba-kids-level-1-topic-13-food-flash-cards/?funnelUUID=a1157ac9-8f7d-44b2-a7fa-33d3fce41be2`,
            practiceVideo: ``,
          },
        },
        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902578642/yoruba-kids-level-1-topic-14-animals-flash-cards/?funnelUUID=dba0cccf-bddb-4b05-a4b1-887c33fcb113`,
            practiceVideo: ``,
          },
        },
        Hobbies: {
          name: "Hobbies",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902588574/yoruba-kids-level-1-topic-15-hobbies-flash-cards/?funnelUUID=ef8bbba1-f58c-4a90-8383-8636a748a7d7`,
            practiceVideo: `<iframe width="853" height="480" src="https://www.youtube.com/embed/HJF1Atm9NRo" title="AARO OSAN ALE | Learn how to GREET AT  DIFFERENT TIMES OF THE DAY   | Yoruba for Kidz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902625146/yoruba-kids-level-1-topic-16-daily-activities-flash-cards/?funnelUUID=b54df49a-fa50-4510-83dc-8536dc3486e8`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 13- 16)": {
          name: "Recap (Session 13- 16)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/916543778/yoruba-kids-level-1-consolidation-set-topic-13-16-flash-cards/?new`,
            practiceVideo: ``,
          },
        },
      },
    },
    Adult: {
      Beginner: {
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWQ5G3LnU/b0VqR3VSARg5V6uzREReZw/edit`,
            quizletSet:
              "https://quizlet.com/921785642/yoruba-level-1-topic-1-greetings-new-flash-cards/?funnelUUID=962785af-39cd-4d3e-becb-7754e1d7a94a",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/FeaSnNJ5p2o?si=OroeSIE6zcZJV8KS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Questions & Introduction": {
          name: "Questions & Introduction",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWSOigpjQ/FECip_BXxKV3eIlqzRy2eQ/edit`,
            quizletSet:
              "https://quizlet.com/921787627/yoruba-level-1-topic-2-questions-and-introduction-new-flash-cards/?funnelUUID=81d98e1b-9db6-4636-9bf5-8cfadbbd0e50",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/iMucYAmBdSE?si=KEJp1CQSPpKVo6Zs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWWLu3yOo/8NcEERJCupKaoRPNOJz9zw/edit`,
            quizletSet:
              "https://quizlet.com/898504689/yoruba-level-1-topic-3-family-new-flash-cards/?funnelUUID=559b5cd7-740f-471c-8049-c464a219eedf",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/sbsi_608AFg?si=3HsaDcVxdC_W4fK7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Around the House": {
          name: "Things around the house",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWWgPoXfY/BpqohWlq9315acHgCG4CVQ/edit?utm_content=DAGWWgPoXfY&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/898623217/yoruba-level-1-topic-4-things-around-the-house-new-flash-cards/?funnelUUID=a3989322-1490-491f-9d9e-fb376259e508`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/9ETfbtulWMw?si=riBhyRzgctFN6zIX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Recap (Session 1- 4)": {
          name: "Recap (Session 1- 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/926466143/test?funnelUUID=1d00ab69-ad03-4d24-bcba-704df3757884`,
            practiceVideo: ``,
          },
        },
        "Places and Transportation": {
          name: "Places and Transportation",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWW8QwRFc/B7lrXuwgsIwELIKOVKF8Kg/edit`,
            quizletSet: `https://quizlet.com/898634259/yoruba-level-1-topic-5-places-transportation-new-flash-cards/?funnelUUID=c80690cb-ee14-4731-94a2-5be56362fb6f`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/pAJWUBlWtVg?si=oNZz9DHvaTf6pWci" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWW5-e1GA/h9YEP7L4sH4JPnDxUN9Nrw/edit`,
            quizletSet: `https://quizlet.com/921788063/yoruba-level-1-topic-6-clothing-new-flash-cards/?funnelUUID=64d78012-025c-4614-ac1f-36cc00e95b27`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/kP6clv2wVZ0?si=2qaD-Vi5F1LqgcK4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Body": {
          name: "Parts of the Body",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXQUgCkc/9-451-14raiDazBIiZD8iA/edit`,
            quizletSet: `https://quizlet.com/898632553/yoruba-level-1-topic-7-parts-of-the-body-new-flash-cards/?funnelUUID=54efac40-caa8-4130-8b3d-77c575210b0d`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HecaIg3leDQ?si=K7sGavF_e0trzlgP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXGEbGTA/r0DsBfwamTOkg6Ooh7K_eg/edit`,
            quizletSet: `https://quizlet.com/898627211/yoruba-level-1-topic-8-food-new-flash-cards/?funnelUUID=4676ba2a-159c-4002-aedc-aacbe2f27beb`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/hi90W1UxNKk?si=cVFbgeeIr9RaDL4t" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
            `,
            quizletSet: `https://quizlet.com/926467156/test?funnelUUID=ea68de25-af30-4c8d-b9a9-132cec903aec`,
            practiceVideo: ``,
          },
        },
        "Recap (Topic 9- 12)": {
          name: "Recap (Topic 9- 12)",
          resources: {
            grammarSpotlight: `
            `,
            quizletSet: `https://quizlet.com/926468047/test?funnelUUID=2deb658d-78c6-453b-9943-da7b1b1377be`,
            practiceVideo: ``,
          },
        },

        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXfElRRY/9xusobf02cgB1E6n1X2qXQ/edit`,
            quizletSet: `https://quizlet.com/921788787/yoruba-level-1-topic-10-animals-flash-cards/?funnelUUID=94c15bc1-ab85-4d95-9977-563b10ad98b1`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/p1TwWVoBkks?si=NWeGpOlGbtIxP2rh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Numbers 1-10": {
          name: "Numbers 1-10",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXjLBbYM/cxNBxwTdhCr-hBZ-S44eEg/edit`,
            quizletSet: `https://quizlet.com/923055566/yoruba-level-1-topic-9-numbers-flash-cards/?funnelUUID=2b5c838d-3e82-4280-b62c-ed0a3eaa127d`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/BQo5xeLT41k?si=Si3BFW9wMWvaMUk5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Time: {
          name: "Time",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWYBj3ivw/uDunEth6FuVzMbFcvnDnDw/edit`,
            quizletSet: `https://quizlet.com/898626223/yoruba-level-1-topic-11-time-new-flash-cards/?funnelUUID=ad2caaf6-16ab-457b-80b6-3f873a0d1a9e`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/yU8rU_Ykbu4?si=l93tdnMvYEy7QuqB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Direction: {
          name: "Direction",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWVno4Bxk/5XWJ-iGrI1h4NDLkNMrpcA/edit`,
            quizletSet: `https://quizlet.com/921789365/yoruba-level-1-topic-12-directions-flash-cards/?funnelUUID=6c9a72c7-151c-4b50-b35e-3be9466bfc4a`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/E5549GnbAIo?si=rN80WcpBCDzC6Ci0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
      },
      Elementary: {
        Weddings: {
          name: "Weddings",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWiI7dSVU/9l1uKGi0tMn6vIsgdmWUkw/edit`,
            quizletSet:
              "https://quizlet.com/898622161/yoruba-level-2-topic-10-weddings-draft-flash-cards/?funnelUUID=8e0b829d-10eb-4bf3-846d-52ed4cb50b51",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/RvYcXXnwp3g?si=_vV59NIFxE-sVocp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Home & Family": {
          name: "Home & Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWc3vYjPc/IDWZtc-wwKRJN1ELs7AsSQ/edit`,
            quizletSet:
              "https://quizlet.com/898631934/yoruba-level-2-topic-4-home-family-flash-cards/?funnelUUID=ebfa4ed2-2f6d-432b-8072-df4fbab65140",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/XdooJyKvi94?si=3rEXeMZOYNJX-seC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWX5JZuQQ/sCepBE24iR5ok-V66-AUxw/edit`,
            quizletSet:
              "https://quizlet.com/898827363/yoruba-level-2-topic-1-daily-activities-draft-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/We4iipBne2Y?si=9AKqpcdJfKDckb0M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Work and School": {
          name: "Work and School",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWcrKmDYU/vwhFUxlE6Qrv3hRWn2Fmlg/edit`,
            quizletSet:
              "https://quizlet.com/899112897/yoruba-level-2-topic-2-work-and-school-draft-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/P7r_ap-Sq-g?si=mGvep0femCpqMtEc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Health: {
          name: "Health",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWc0cYn0A/AFDJ7pe27kea_AKZ1PpdOQ/edit`,
            quizletSet: `https://quizlet.com/898899560/yoruba-level-2-topic-3-health-draft-flash-cards/?funnelUUID=0dc4bd58-bab4-4064-b437-d3d520ae03ef`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/JMBoXa6SOto?si=Hgveq0kZvag6QVCj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/944329225/test?funnelUUID=5f59cd0a-a789-42a4-951a-1c51e06e4520`,
            practiceVideo: ``,
          },
        },
        Shopping: {
          name: "Shopping",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWducJy_w/FxdnZYqTnVNGPQzfoCLzIA/edit`,
            quizletSet: `https://quizlet.com/898904286/yoruba-level-2-topic-6-shopping-draft-flash-cards/?funnelUUID=4ffbb953-76c1-4116-a2a3-4157b417b2b0`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HttHhZK1s1E?si=eBd621HRwpnBM-FY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Restaurant: {
          name: "Restaurant",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWdyoAVUM/CZ8X7v7uvcGF6JnLpzRUDQ/edit`,
            quizletSet: `https://quizlet.com/899106099/yoruba-level-2-topic-7-restaurant-draft-flash-cards/?funnelUUID=acf366d9-318e-49a8-b928-aab3728e72e0`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/65F4Xc5XiXE?si=z3YVWqE9M3Hnf-2W" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Travel: {
          name: "Travel",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWd5vEAfA/27OKEMr9J174vllN44oCjw/edit`,
            quizletSet: `https://quizlet.com/899107444/yoruba-level-2-topic-8-travel-draft-flash-cards/?funnelUUID=be785348-12b2-4158-9f13-5ce4a41da085`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/PT0Nqlg_uns?si=aVBzP27vF4fbQVhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Numbers 11 - 20": {
          name: "Numbers 11 - 20",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMhuq7lpA/3vsSJ-FdiZkMa_u5DokN1w/edit`,
            quizletSet: `https://quizlet.com/gb/850037062/session-8-numbers-11-20-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1i8ZZxHeVCfRqQuy4SAtvFtQE2XWGVJ_B/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        "Numbers 13 - 1000": {
          name: "Numbers 13 - 1000",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWdPfB6P8/OASfyN2l0bPk7K9OKaA21A/edit`,
            quizletSet: `https://quizlet.com/899108975/yoruba-level-2-topic-5-numbers-13-1000-draft-flash-cards/?funnelUUID=e04951df-bfd3-4aa5-9895-132bce197299`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/zM7fiCwoo1Q?si=JZHnleYJ_ZffVViU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Session 5 - 8)": {
          name: "Consolidation Quiz (Session 5 - 8)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/944332488/test?funnelUUID=557e1db5-78b0-47e7-98ba-3adca4ea36f7`,
            practiceVideo: ``,
          },
        },

        "Expressing Emotions": {
          name: "Expressing Emotions",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWiIiXlHY/Uznbp1ts5vl0Gy_gUn_JEg/edit`,
            quizletSet: `https://quizlet.com/899109853/yoruba-level-2-topic-9-expressing-emotions-draft-flash-cards/?funnelUUID=004dd13b-30cb-4d0d-b647-50aeb4dc8970`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/laxsquxFZvo?si=pSqUj6O1ZZdGtmv0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Culture: {
          name: "Culture",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/850442085/session-10-culture-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/10zjN9h1eQNtzmBbtAcoK_18qhDeUA7E_/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Weather: {
          name: "Weather",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWZF03x_8/l7xALiHr8trkOdfewYrBjw/edit`,
            quizletSet: `https://quizlet.com/899114327/yoruba-level-2-topic-12-weather-draft-flash-cards/?funnelUUID=771f5900-f496-4703-ba16-d73977e3c2e0`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/yjzZdTQFgxs?si=azXIa17tAtAVMm9O" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Sports: {
          name: "Sports",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWZt2BxdU/V-bp2EXTGFoiptT47bGabg/edit`,
            quizletSet: `https://quizlet.com/899170263/yoruba-level-2-topic-11-sports-and-hobbies-draft-flash-cards/`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/76hz28BGE2Q?si=jyxDZXHHTyCdA3E5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Sports and Hobbies": {
          name: "Sports and Hobbies",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWZt2BxdU/V-bp2EXTGFoiptT47bGabg/edit`,
            quizletSet: `https://quizlet.com/899170263/yoruba-level-2-topic-11-sports-and-hobbies-draft-flash-cards/`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/76hz28BGE2Q?si=jyxDZXHHTyCdA3E5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Session 9 - 12)": {
          name: "Consolidation Quiz (Session 9 - 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/944341416/test?funnelUUID=c3f237bc-fc07-4cc2-bc6e-6eb8e13f92e9`,
            practiceVideo: ``,
          },
        },
      },
      Intermediate: {
        "Describing Yourself": {
          name: "Describing Yourself",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWiZ53ddA/jML3g_SvNxOiy7FVnT2nig/edit`,
            quizletSet:
              "https://quizlet.com/957500965/yoruba-level-3-topic-1-describing-yourself-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/vKfNKSCbzAM?si=38EdzssmBLaY0rSU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Household Chores": {
          name: "Household Chores",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWizBbsEU/P9VTBzKM4-G8F-IwCcp2jA/edit`,
            quizletSet:
              "https://quizlet.com/957525570/yoruba-level-3-topic-2-home-activities-flash-cards",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/4B9GzNs3TDg?si=xvXYz0UkSB_wAk_E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Cooking: {
          name: "Cooking",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWi2hfY9M/2-UsXec85kaRuStVTP4sZA/edit`,
            quizletSet:
              "https://quizlet.com/957549329/yoruba-level-3-topic-3-cooking-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/b5sSa-r5tdk?si=gHm5CnRHW4d3RJiq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Body and Health": {
          name: "Body and Health",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWi7TuI60/9xbu8fy_jamDrNVRLbxOQQ/edit`,
            quizletSet:
              "https://quizlet.com/957577000/yoruba-level-3-topic-4-body-and-health-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/4Dj5V_J952o?si=r_as7vRlWYs9j4mh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Business: {
          name: "Business",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGXmKIU8Og/3bOxfsm18bQiSIh6mZo2bA/edit?ui=eyJIIjp7IkEiOnRydWV9fQ`,
            quizletSet:
              "https://quizlet.com/973555092/yoruba-level-3-topic-5-business-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/8myYorMqO4g?si=l62CIBGzihqqC46e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Friendship and Relationship": {
          name: "Friendship and Relationship",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGX2oEjkEM/Kbqu8_EfAmmTtW0jBURvSg/edit?ui=eyJIIjp7IkEiOnRydWV9fQ`,
            quizletSet:
              "https://quizlet.com/973587216/yoruba-level-3-topic-6-friendship-and-relationship-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/4fdD82gMucY?si=Xegcp4UVYUx7W80t" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Asking for help": {
          name: "Asking for help",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGYIxaLMQ8/i1AX5MFZIWVGLJUd4s2UnQ/edit?ui=eyJIIjp7IkEiOnRydWV9fQ`,
            quizletSet:
              "https://quizlet.com/978640071/yoruba-level-3-topic-7-asking-for-help-flash-cards/?new",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/0IJJ2N42HZ8?si=8RANyhVS-HFkQDx_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Recap (Topic 1- 4)": {
          name: "Recap (Topic 1- 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/962516423/test?funnelUUID=6175cf6c-4568-4033-aef0-36eb77ce9761",
            practiceVideo: "",
          },
        },
      },
    },
  },
  Igbo: {
    Child: {
      Beginner: {
        "Introduction & Manners": {
          name: "Introduction & Manners",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901969534/igbo-kids-level-1-topic-3-manners-flash-cards/?funnelUUID=1352b229-42e0-4aa6-b23d-652a91cad3e1",
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/hMu-KCPTOqw?si=2ZqO86AuLSTFiAt2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901944233/igbo-kids-level-1-topic-2-greetings-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/pKclg25WHjw?si=gR8J8qP2WWzdj98e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Introductions: {
          name: "Introductions",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/901964983/igbo-kids-level-1-topic-3-introductions-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/GZMIjZFiXsg?si=UCUGMduqtxXyiuSQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Emotions: {
          name: "Emotions",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/gb/902325480/igbo-kids-level-1-topic-4-basic-emotions-flash-cards/?i=57u171&x=1jqt",
            practiceVideo: "",
          },
        },

        "Recap (Session 1-4)": {
          name: "Recap (Session 1-4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/916581361/topic-1-4-consolidation-set-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: ``,
          },
        },
        "Core Family": {
          name: "Core Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/901978185/igbo-kids-level-1-topic-4-core-family-flash-cards/?funnelUUID=2ebddb49-5a63-4da8-a324-3cbb9957f2d2`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/EMbGvFOK7xk?si=hcoArW3BXMvS6IEB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Close Family": {
          name: "Close Family",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902328611/igbo-kids-level-1-topic-5-close-family-flash-cards/?funnelUUID=f925fb48-7c5e-4350-8add-4de5ab80145b`,
            practiceVideo: ``,
          },
        },
        Friends: {
          name: "Friends",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902337845/igbo-level-1-topic-6-friends-flash-cards/?funnelUUID=7a2f0ba5-6fd7-494d-9abe-2bffb0f7c870`,
            practiceVideo: ``,
          },
        },
        School: {
          name: "School",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902342185/igbo-kids-level-1-topic-7-school-flash-cards/?funnelUUID=a32c9ce1-241b-40c9-91b8-f54f508b6f47`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=2-NvMOBVlb6-gxp6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Around the House": {
          name: "Around the House",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902344794/igbo-kids-level-1-topic-8-around-the-house-flash-cards/?funnelUUID=342b0990-bd18-481f-90bb-b245c2c19a59`,
            practiceVideo: ``,
          },
        },
        "Recap  (Session 5- 8)": {
          name: "Recap  (Session 5- 8)",
          resources: {
            grammarSpotlight: `
                `,
            quizletSet: `https://quizlet.com/854160071/test?funnelUUID=c59da0bd-dbb6-4000-81a9-f321d19f1cc8`,
            practiceVideo: ``,
          },
        },

        Colours: {
          name: "Colours",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902354474/igbo-kids-level-1-topic-9-colours-flash-cards/?funnelUUID=ae56fc2c-f96c-4347-9ff1-b95a4f1bf0d7`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=tavYNWaJNCI09Qa4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902357817/igbo-kids-level-1-topic-10-clothings-flash-cards/?funnelUUID=5050ccd1-5530-455f-a5c0-bb02bebfb5fb`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/RiJDQnXinvM?si=d1Ir6Gm6IoozbbTo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902362263/igbo-kids-level-1-topic-11-numbers-flash-cards/?funnelUUID=dfa30895-94ba-4e45-8a41-1d82cb991e83`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HJF1Atm9NRo?si=2iQU6ouWWFhBy-8E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Face": {
          name: "Parts of the Face",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902365162/igbo-kids-level-1-topic-12-parts-of-the-face-flash-cards/?funnelUUID=ace91402-2e71-4938-8862-6535389f870b`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 9- 12)": {
          name: "Recap (Session 9- 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/854157642/test?funnelUUID=4c694272-d2c6-4cb4-bed0-7ea267cfc0cd`,
            practiceVideo: ``,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902863705/igbo-kids-level-1-topic-13-food-flash-cards/?funnelUUID=8147fcf6-8d14-4207-90bf-a8cf7c4bcf7f`,
            practiceVideo: ``,
          },
        },
        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902868486/igbo-kids-level-1-topic-14-animals-flash-cards/?funnelUUID=c45fbcaf-6d96-488c-a574-5042334e9a64`,
            practiceVideo: ``,
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/gb/902877991/igbo-kids-level-1-topic-16-daily-activities-flash-cards/?funnelUUID=27a916cf-500f-49fe-b945-fd2dc9927384`,
            practiceVideo: ``,
          },
        },
        "Recap (Session 13- 16)": {
          name: "Recap (Session 13- 16)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/854160071/test?funnelUUID=c59da0bd-dbb6-4000-81a9-f321d19f1cc8`,
            practiceVideo: ``,
          },
        },
      },
    },
    Adult: {
      Beginner: {
        Greetings: {
          name: "Greetings",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWRkXs9Sk/mKuTirO-CAhh2oNKib33CA/edit`,
            quizletSet:
              "https://quizlet.com/gb/921771769/igbo-level-1-topic-1-greetings-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/EQzMJJoOWkU?si=-ofYhD_dZxanriFw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Questions & Introduction": {
          name: "Questions & Introduction",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWSpCIWjg/EGqInO7Uqpt83dIpcwJNxw/edit`,
            quizletSet:
              "https://quizlet.com/921764099/igbo-level-1-topic-2-questions-and-introduction-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/vi6zYf6p1kw?si=CuE2fhKwTcICpd0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWWZQMwYA/qrNiR9nhLS46Bwa82QhSqA/edit`,
            quizletSet:
              "https://quizlet.com/921776678/igbo-level-1-topic-3-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/P9gvE8G7Ef0?si=TSV-cyHDwdiJ_bX-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Things Around the House": {
          name: "Things around the house",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXFun9Vg/Iq8USPCl5S95IzBbPfZBrQ/edit`,
            quizletSet: `https://quizlet.com/922777373/igbo-level-1-topic-4-things-around-the-house-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/1a19TL8tz0M?si=sZcYDone8_eR8Bcg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/test/igbo-consolidation-quiz-session-1-4-june-2024-926478410?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=4&showImages=true`,
            practiceVideo: ``,
          },
        },
        "Places and Transportation": {
          name: "Places and Transportation",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXOQeTJc/KXOg4-VsblBCuPNjUu37_A/edit`,
            quizletSet: `https://quizlet.com/922781433/igbo-level-1-topic-5-places-and-transportation-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/YbyQMTIZRog?si=6HjWhHh36_6KpRMn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Clothing: {
          name: "Clothing",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXbXEoC8/8IUatNe8kJIf6PgmEe6N7g/edit`,
            quizletSet: `https://quizlet.com/922784105/igbo-level-1-topic-6-clothing-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/oiwZvgtBLEY?si=6zKw72Dsck2fw_E_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Parts of the Body": {
          name: "Parts of the Body",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXaOQYt0/J9r0aML8TdSqnoxMQ2Egog/edit`,
            quizletSet: `https://quizlet.com/923034272/igbo-level-1-topic-7-parts-of-the-body-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/GGmUPL7ivpo?si=p0OJr9647vXjwUBf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Food: {
          name: "Food",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXviaZhY/sKCfEYCU5Cdc2SJuPeJK2A/edit`,
            quizletSet: `https://quizlet.com/923036958/igbo-level-1-topic-8-food-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/tXirvEexkn4?si=7xjaoEFZ8HNkLjOT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Sessions 5- 8)": {
          name: "Consolidation Quiz (Sessions 5- 8)",
          resources: {
            grammarSpotlight: `
            `,
            quizletSet: `https://quizlet.com/926488473/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=4&showImages=true`,
            practiceVideo: ``,
          },
        },

        Animals: {
          name: "Animals",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWX4Ikv1M/ltxBylcnbhGkx1Sl-N8gYw/edit`,
            quizletSet: `https://quizlet.com/923042670/igbo-level-1-topic-10-animals-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/NttH-MUpM2I?si=Gkb78d3FxyooSW0D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Numbers: {
          name: "Numbers",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWXi4i5cs/_hQIndcMoETXnsWGUfrukg/edit`,
            quizletSet: `https://quizlet.com/923040108/igbo-level-1-topic-9-numbers-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/r6uMnJ9IoK8?si=21ZMk9GBrIeDOVzV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Time: {
          name: "Time",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWX4GB7o4/gy15SD8eMVVgmrCccePUgg/edit`,
            quizletSet: `https://quizlet.com/923044977/igbo-level-1-topic-11-time-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/FD_yVTSgaxM?si=0O17WcBCr69Bhze5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Direction: {
          name: "Direction",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWYN396xI/HJTQ2iYp6uG7q5wu3AWlig/edit`,
            quizletSet: `https://quizlet.com/923048595/igbo-level-1-topic-12-directions-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/gscyJgX0njc?si=XTnvRGFekO5ShkKv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Sessions 9- 12)": {
          name: "Consolidation Quiz (Sessions 9- 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/926490035/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=4&showImages=true`,
            practiceVideo: ``,
          },
        },
      },
      Elementary: {
        Wedding: {
          name: "Wedding",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWiC8Datk/Zl1wLmGcYwpAoozp0_-UwQ/edit`,
            quizletSet:
              "https://quizlet.com/929316525/igbo-level-2-topic-10-wedding-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/MFPtfKpaEug?si=3uBthR7r4lLf-tNC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Home & Family": {
          name: "Home & Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGMlcMpf68/PdcyLzSU1JE-UN2ae4Z35Q/edit?utm_content=DAGMlcMpf68&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/gb/849546706/igbo-level-2-session-2-home-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe src="https://drive.google.com/file/d/1fMNnVzVBfSuXzkAXvDSjp8M1nlZQdPRQ/preview" width="640" height="480" allow="autoplay"></iframe>',
          },
        },
        Family: {
          name: "Family",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWeC2x2aU/Zbfp8HsiqZwft4qh0ynAag/edit`,
            quizletSet:
              "https://quizlet.com/929274159/igbo-level-2-topic-4-family-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/zDG1oifHLDw?si=0xkNBJ6D6bfV0UkB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Daily Activities": {
          name: "Daily Activities",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWdGCWkIQ/-kX3cMp7Mwi9rOd0Rv3j5Q/edit`,
            quizletSet:
              "https://quizlet.com/929243860/igbo-level-2-topic-1-daily-activities-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/7t7miSvnuNo?si=mRvirlSco-9Xs7pd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Work & School": {
          name: "Work & School",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWdd5iDog/1tbRLYvKnk7wr_yptFeoPw/edit`,
            quizletSet:
              "https://quizlet.com/929247952/igbo-level-2-topic-2-work-and-school-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/JL4fkNi44o8?si=YWKW1QeUZdwFp9qO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Health: {
          name: "Health",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWd_POWBU/oKKrk8jaPDjvlzSmnXCzQg/edit`,
            quizletSet: `https://quizlet.com/929271639/igbo-level-2-topic-3-health-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/I1E8p0C89nY?si=1qgyLIO-tPgqlKKX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Session 1 - 4)": {
          name: "Consolidation Quiz (Session 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/934840948/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=4&showImages=true`,
            practiceVideo: ``,
          },
        },
        Shopping: {
          name: "Shopping",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWejJ4Hh8/SeE7bCUPxLVyxlKYH5P6rA/edit`,
            quizletSet: `https://quizlet.com/929288249/igbo-level-2-topic-6-shopping-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/dQFfMrH24oE?si=XcquZ-f2BX6PTQlu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Restaurant: {
          name: "Restaurant",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWeh0AKd4/SukojfQLMz7rAe0cXYXqpw/edit`,
            quizletSet: `https://quizlet.com/929311642/igbo-level-2-topic-7-restaurant-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/9MUrXQS6Mnk?si=t65fQVf6mKNW1fEa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Travel: {
          name: "Travel",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWe9Ev2Uc/8R56y8l556ND7uJiiwFqvg/edit`,
            quizletSet: `https://quizlet.com/929313402/igbo-level-2-topic-8-travel-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/fljNLg60pzU?si=-tgbrgieRuLUqYDc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Numbers 11 - 20": {
          name: "Numbers 11 - 20",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWebO1giY/NJG6EC5dASUyK51MKbzuKQ/edit`,
            quizletSet: `https://quizlet.com/929314856/igbo-level-2-topic-5-numbers-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/wegyJuXHiC4?si=ltPKrfMSg7XFcvm5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Numbers 11+": {
          name: "Numbers 11+",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWebO1giY/NJG6EC5dASUyK51MKbzuKQ/edit`,
            quizletSet: `https://quizlet.com/929314856/igbo-level-2-topic-5-numbers-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/wegyJuXHiC4?si=ltPKrfMSg7XFcvm5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Session 5 - 8)": {
          name: "Consolidation Quiz (Session 5 - 8)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/934841567/test?answerTermSides=2&promptTermSides=6&questionCount=40&questionTypes=4&showImages=true`,
            practiceVideo: ``,
          },
        },

        "Expressing Emotions": {
          name: "Expressing Emotions",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWe27fP2w/95a0cXpbK2SHV6uxM4fPZg/edit`,
            quizletSet: `https://quizlet.com/929315646/igbo-level-2-topic-9-expressing-feelings-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/dcp1855zaYs?si=MsGd3e0i0KugfmcY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Culture: {
          name: "Culture",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAF5ej3ke6w/9gw_ZS6ZyAfmWPY6LLdMDg/edit?utm_content=DAF5ej3ke6w&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet: `https://quizlet.com/gb/862007892/igbo-level-2-session-10-culture-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe src="https://drive.google.com/file/d/1_2H9Q5CoekKpQriy7yhvbocHBTVCOwtW/preview" width="640" height="480" allow="autoplay"></iframe>`,
          },
        },
        Weather: {
          name: "Weather",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWYwMpRVQ/n-gozDDSOln3YRHj1LIfEA/edit`,
            quizletSet: `https://quizlet.com/929319977/igbo-level-2-topic-12-weather-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/HMytA3soR6k?si=NYT83U5v5hoKfjrA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        Sports: {
          name: "Sports",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWZbZPO9c/7ZbihNnsZr1fJFOxUXIpHQ/edit`,
            quizletSet: `https://quizlet.com/929318006/igbo-level-2-topic-11-sports-and-hobbies-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/H1jnPv-gALc?si=mHS_UIUDbSlXA1LX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Sports and Hobbies": {
          name: "Sports and Hobbies",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWZbZPO9c/7ZbihNnsZr1fJFOxUXIpHQ/edit`,
            quizletSet: `https://quizlet.com/929318006/igbo-level-2-topic-11-sports-and-hobbies-flash-cards/?i=57u171&x=1jqt`,
            practiceVideo: `<iframe width="560" height="315" src="https://www.youtube.com/embed/H1jnPv-gALc?si=mHS_UIUDbSlXA1LX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
          },
        },
        "Consolidation Quiz (Session 9 - 12)": {
          name: "Consolidation Quiz (Session 9 - 12)",
          resources: {
            grammarSpotlight: ``,
            quizletSet: `https://quizlet.com/876229402/test?answerTermSides=2&promptTermSides=4&questionCount=40&questionTypes=14&showImages=true`,
            practiceVideo: ``,
          },
        },
      },
      Intermediate: {
        "Describing Yourself": {
          name: "Describing Yourself",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWi6uJXn0/-9yyHgl7riGAj55uACZf_g/edit`,
            quizletSet:
              "https://quizlet.com/955865381/igbo-level-3-topic-1-describing-yourself-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/UgFGX6p3-n8?si=jgfF40faF86txg9f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Household Chores": {
          name: "Household Chores",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWjAqWF_c/Go8vF95o8vdawUQ-UmA7nA/edit`,
            quizletSet:
              "https://quizlet.com/955839902/igbo-level-3-topic-2-household-chores-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/ASmF-RUg-Ow?si=NVlF-OZNPzOQvx8Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        Cooking: {
          name: "Cooking",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWjfkx7WQ/-9GiO2OioCffdiHdRZR5nw/edit`,
            quizletSet:
              "https://quizlet.com/955878490/igbo-level-3-topic-3-cooking-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/7oLZCUu-uag?si=k7b9IEBD-o_Dn-mf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Body and Health": {
          name: "Body and Health",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGWjtVOBQ0/th5Ha0chc9OTHA71XheAwA/edit`,
            quizletSet:
              "https://quizlet.com/955898278/igbo-level-3-topic-4-body-and-health-flash-cards/?i=57u171&x=1jqt",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/6KbZGyIxaHc?si=Nx0yecMGOK6J9U3P" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Consolidation Quiz (Topics 1 - 4)": {
          name: "Consolidation Quiz (Topics 1 - 4)",
          resources: {
            grammarSpotlight: ``,
            quizletSet:
              "https://quizlet.com/962610425/test?answerTermSides=4&promptTermSides=6&questionCount=40&questionTypes=4&showImages=true",
            practiceVideo: "",
          },
        },
        Business: {
          name: "Business",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGYI3fEtIM/oAT_cgi980GH2fcpFfTEVg/edit`,
            quizletSet:
              "https://quizlet.com/970206947/igbo-level-3-topic-5-business-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/Y80PW29pmD4?si=sEUb8_SlxFfcwoJn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Friendship and Relationship": {
          name: "Friendship and Relationship",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGYNceMv-I/_IvXMHpQNoCg6ShHdpgA4A/edit?utm_content=DAGYNceMv-I&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/gb/970214883/igbo-level-3-topic-6-friendship-and-relationship-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/Oqy1UZgNRx0?si=8H1FsnmpqT_bA5Ci" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
        "Asking for help": {
          name: "Asking for help",
          resources: {
            grammarSpotlight: `https://www.canva.com/design/DAGYPb_heYE/uKZVCOsGqiz10kqLggDlPg/edit?utm_content=DAGYPb_heYE&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton`,
            quizletSet:
              "https://quizlet.com/979074745/igbo-level-topic-7-asking-for-help-flash-cards/",
            practiceVideo:
              '<iframe width="560" height="315" src="https://www.youtube.com/embed/CGqmRdWfXLc?si=Vtx9GAApv0tkrTpo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
          },
        },
      },
    },
  },
};

export interface LessonResources {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          name: string;
          resources: {
            grammarSpotlight: string;
            quizletSet: string;
            practiceVideo: string;
          };
        };
      };
    };
  };
}
