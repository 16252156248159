export const routes = {
  // Tutor Oboarding and dashboard
  TUTOR_SIGNUP: {
    label: "Tutor Signup",
    url: "/tutor-signup",
  },
  HOME: {
    label: "Home",
    url: "/",
  },
  ONBOARDING_EXERCISE: {
    label: "Onboarding Exercise",
    url: "/onboarding-exercise",
  },
  TUTOR_PAYMENT_INFO: {
    label: "Payment Preferences",
    url: "/tutor/dashboard/profile/payment-info",
  },
  YORUBA_LANDING: {
    label: "Yoruba Landing",
    url: "/book-yoruba",
  },
  ABOUT: {
    label: "About",
    url: "/about",
  },
  CHEMISTRY: {
    label: "Chemistry",
    url: "/chemistry",
  },
  TERMS: {
    label: "Terms",
    url: "/terms",
  },
  SERVICES: {
    label: "Services",
    url: "/services",
  },
  LANGUAGE: {
    label: "Language",
    url: "/language",
  },
  ACADEMIC: {
    label: "Academic",
    url: "/academic",
  },
  CONTACT: {
    label: "Contact",
    url: "/contact",
  },
  PRIVACY_POLICY: {
    label: "Privacy Policy",
    url: "/privacy-policy",
  },
  TEACHER_REFERRAL_PROGRAM_SIGNUP: {
    label: "Teacher Refferal Signup",
    url: "/ambassadors/signup",
  },
  TEACHER_REFERRAL_PROGRAM_HOME: {
    label: "Overview",
    url: "/ambassadors/home",
  },
  TEACHER_REFERRAL_PROGRAM_PROFILE: {
    label: "Details",
    url: "/ambassadors/profile",
  },
  TEACHER_REFERRAL_PAYMENT_INFO: {
    label: "Payment Info",
    url: "/ambassadors/payment-info",
  },
  TEACHER_REFERRAL_DASHBOARD_SETTINGS: {
    label: "Settings",
    url: "/ambassadors/settings",
  },
  STUDENT_LESSON_FEEDBACK: {
    label: "Student Lesson Feedback",
    url: "/student-lesson-feedback",
  },
  REPORT_NO_SHOW: {
    label: "Report No Show",
    url: "/tutor/dashboard/report-no-show",
  },
  COOKIE_POLICY: {
    label: "Cookie Policy",
    url: "/cookie-policy",
  },
  SENTENCE_BUILDER: {
    // Beta version
    label: "Sentence Builder",
    url: "/sentence-builder",
  },
  SENTENCE_CONSTRUCTION: {
    label: "Sentence Construction Game",
    url: "/sentence-construction",
  },
  SENTENCE_CONSTRUCTION_SESSION: {
    label: "Sentence Construction Game",
    url: "/sentence-construction-session",
  },
  SENTENCE_CONSTRUCTION_SELECTION: {
    label: "Sentence Construction Selection",
    url: "/sentence-construction-selection",
  },
  TEST_YOUR_KNOWLEDGE: {
    label: "Test Your Knowledge",
    url: "/test-your-knowledge",
  },
  FLASHCARD_SESSION: {
    label: "Flashcard Game",
    url: "/flashcards",
  },
  LEARNING_APP: {
    label: "Learning App",
    url: "/learn",
  },
  WORD_GENERATOR: {
    label: "Word Generator",
    url: "/minigames/word-generator",
  },
  STUDENT_SIGNUP: {
    label: "Student Signup",
    url: "/student-signup",
  },
  STUDENT_EMAIL_SIGNUP: {
    label: "Student Email Signup",
    url: "/student-email-signup",
  },
  GOOGLE_CALLBACK: {
    label: "Google Callback",
    url: "/auth/google/callback",
  },
  TUTOR_EMAIL_SIGNUP: {
    label: "Tutor Email Signup",
    url: "/tutor-email-signup",
  },
  VERIFY_EMAIL: {
    label: "Verify Email",
    url: "/verify-email",
  },
  PRICES: {
    label: "Prices",
    url: "/lesson-prices",
  },
  VIRTUAL_IMMERSIONS: {
    label: "Virtual Immersions",
    url: "/virtual-immersions",
  },
  LOGIN: {
    label: "Login",
    url: "/login",
  },
  UPDATE_PASSWORD: {
    label: "Update Password",
    url: "/update-password",
  },
  USER_IMPERSONATION: {
    label: "User Impersonation",
    url: "/sign-in-as-user",
  },
  CREATE_PASSWORD: {
    label: "Create Password",
    url: "/create-password",
  },
  RESET_PASSWORD: {
    label: "Reset Password",
    url: "/reset-password",
  },
  FORGOT_PASSWORD: {
    label: "Forgot Password",
    url: "/forgot-password",
  },
  ONBOARDING_STEP_0: {
    label: "Onboarding Step 0",
    url: "/tutor/onboarding/step0",
  },
  ONBOARDING_STEP_1: {
    label: "Onboarding Step 1",
    url: "/tutor/onboarding/step1",
  },
  ONBOARDING_STEP_ABOUT_YOURSELF: {
    label: "Onboarding Step 1",
    url: "/tutor/onboarding/about-tutor",
  },
  STUDENT_DASHBOARD_OVERVIEW: {
    label: "Overview",
    url: "/student/dashboard/overview",
  },
  STUDENT_DASHBOARD_REWARDS: {
    label: "Rewards",
    url: "/student/dashboard/rewards",
  },
  STUDENT_LEADERBOARD: {
    label: "Leaderboard",
    url: "/student/dashboard/leaderboard",
  },
  STUDENT_DASHBOARD_VIRTUAL_IMMERSIONS: {
    label: "Virtual Immersions",
    url: "/student/dashboard/virtual-immersions",
  },
  STUDENT_DASHBOARD_NOTIFICATIONS: {
    label: "Notifications",
    url: "/student/dashboard/notifications",
  },
  STUDENT_DASHBOARD_SCRATCHGAMES: {
    label: "Scratch Games",
    url: "/student/dashboard/minigames/scratch-games",
  },
  STUDENT_DASHBOARD_MINIGAMES: {
    label: "Minigames",
    url: "/student/dashboard/minigames",
  },
  TUTOR_DASHBOARD_NOTIFICATIONS: {
    label: "Notifications",
    url: "/tutor/dashboard/notifications",
  },
  TUTOR_DASHBOARD_LESSON_REWARDS: {
    label: "Lesson Rewards",
    url: "/tutor/dashboard/lesson-rewards",
  },
  // STUDENT_DASHBOARD_PROFILE: {
  //   label: "Details",
  //   url: "/student/dashboard/profile/details",
  // },
  STUDENT_DASHBOARD_BIO: {
    label: "Details",
    url: "/student/dashboard/profile/details",
  },
  STUDENT_DASHBOARD_SETTINGS: {
    label: "Settings",
    url: "/student/dashboard/profile/settings",
  },
  STUDENT_DASHBOARD_PAYMENTS: {
    label: "Payments",
    url: "/student/dashboard/profile/payments",
  },

  STUDENT_DASHBOARD_ACADEMIC_INFO: {
    label: "Academic Info",
    url: "/student/dashboard/profile/academic",
  },
  STUDENT_DASHBOARD_MY_TUTORS: {
    label: "My Tutors",
    url: "/student/dashboard/my-tutors",
  },
  STUDENT_DASHBOARD_BOOKINGS: {
    label: "Manage",
    url: "/student/dashboard/lessons/manage",
  },
  STUDENT_DASHBOARD_RESOURCES: {
    label: "Resources",
    url: "/student/dashboard/lessons/resources",
  },
  STUDENT_DASHBOARD_LEARN: {
    label: "Learn",
    url: "/student/dashboard/learn",
  },
  DEPENDANT_DASHBOARD_LEARN: {
    label: "Learn",
    url: "/student/dashboard/dependant/learn",
  },
  STUDENT_DASHBOARD_UPCOMING_LESSONS: {
    label: "Upcoming",
    url: "/student/dashboard/lessons/upcoming",
  },
  STUDENT_DASHBOARD_PAST_LESSONS: {
    label: "History",
    url: "/student/dashboard/lessons/past",
  },
  STUDENT_DASHBOARD_MESSAGES: {
    label: "Messages",
    url: "/student/dashboard/messages",
  },
  STUDENT_DASHBOARD_FEEDBACK: {
    label: "Feedback",
    url: "/student/dashboard/lessons/feedback",
  },
  STUDENT_REFERRAL: {
    label: "Referral",
    url: "/student/dashboard/student-referral",
  },
  DEPENDANT_DASHBOARD_MESSAGES: {
    label: "Messages",
    url: "/student/dashboard/dependant/messages",
  },
  TUTOR_DASHBOARD_ONBOARDING: {
    label: "Onboarding",
    url: "/tutor/dashboard/profile/onboarding",
  },
  TUTOR_DASHBOARD_BIO: {
    label: "Details",
    url: "/tutor/dashboard/profile/details",
  },
  TUTOR_DASHBOARD_FEEDBACK: {
    label: "Feedback",
    url: "/tutor/dashboard/feedback",
  },
  TUTOR_DASHBOARD_STUDENT_NOTES: {
    label: "Notes",
    url: "/tutor/dashboard/student-notes",
  },
  TUTOR_DASHBOARD_STUDENT_PROFILE: {
    label: "Student Profile",
    url: "/tutor/dashboard/student-profile",
  },
  TUTOR_DASHBOARD_AVATAR: {
    label: "Avatar",
    url: "/tutor/dashboard/profile/avatar",
  },
  TUTOR_DASHBOARD_SETTINGS: {
    label: "Settings",
    url: "/tutor/dashboard/profile/settings",
  },
  TUTOR_DASHBOARD_OVERVIEW: {
    label: "Overview",
    url: "/tutor/dashboard/profile/overview",
  },
  TUTOR_DASHBOARD_LESSONS: {
    label: "Manage",
    url: "/tutor/dashboard/profile/lessons/manage",
  },
  TUTOR_DASHBOARD_UPCOMING_LESSONS: {
    label: "Upcoming",
    url: "/tutor/dashboard/profile/lessons/upcoming",
  },
  TUTOR_DASHBOARD_PAST_LESSONS: {
    label: "History",
    url: "/tutor/dashboard/profile/lessons/past",
  },
  TUTOR_DASHBOARD_BOOKINGS: {
    label: "Requests",
    url: "/tutor/dashboard/profile/lessons/requests",
  },
  TUTOR_DASHBOARD_STUDENTS: {
    label: "Students",
    url: "/tutor/dashboard/profile/students",
  },
  TUTOR_DASHBOARD_AVAILABILITY: {
    label: "Availability",
    url: "/tutor/dashboard/profile/availability",
  },
  TUTOR_DASHBOARD_MESSAGES: {
    label: "Messages",
    url: "/tutor/dashboard/messages",
  },
  TUTOR_DASHBOARD_HOMEWORK: {
    label: "Homework",
    url: "/tutor/dashboard/homework",
  },
  STUDENT_DASHBOARD_HOMEWORK: {
    label: "Homework",
    url: "/student/dashboard/homework",
  },
  FIND_A_TUTOR: {
    label: "Find a Tutor",
    url: "/find-a-tutor",
  },
  VIEW_TUTOR_PROFILE: {
    label: "View Tutor Profile",
    url: "/view-tutor-profile",
  },
  VIEW_TUTOR_PROFILE_DIRECT_PAY: {
    label: "View Tutor Profile",
    url: "/welcome/a/get-started/view-tutor-profile",
  },
  HOW_IT_WORKS: {
    label: "How it Works",
    url: "/how-it-works",
  },
  FIND_GROUP_LESSONS: {
    label: "Group Lessons",
    url: "/group-lessons",
  },
  VIEW_GROUP_LESSON: {
    label: "View Group Lesson",
    url: "/view-group-lesson",
  },
  BOOK_A_LESSON: {
    label: "Book A One-on-One Lesson",
    url: "/book-a-lesson",
  },
  BOOK_A_LESSON_VIA_ADMIN: {
    label: "Book a Lesson",
    url: "/request-a-lesson",
  },
  BOOK_A_GROUP_LESSON: {
    label: "Book A Group Lesson",
    url: "/book-a-group-lesson",
  },
  BECOME_A_TUTOR: {
    label: "Become a Tutor",
    url: "/become-a-tutor",
  },
  BECOME_A_TUTOR_NEXT_STEPS: {
    label: "Become a Tutor, Next Steps",
    url: "/become-a-tutor/next-steps",
  },
  TERMS_AND_CONDITIONS: {
    label: "Terms and Conditions",
    url: "/terms",
  },
  ACADEMIC_THANK_YOU: {
    label: "Book an Academic Lesson, Thank You",
    url: "/request-academic-lesson/thank-you",
  },
  LANGUAGE_THANK_YOU: {
    label: "Book a Language Lesson, Thank You",
    url: "/request-language-lesson/thank-you",
  },
  CHECKOUT: {
    label: "Checkout",
    url: "/checkout",
  },
  CHECKOUT_THANK_YOU: {
    label: "Payment Confirmation",
    url: "/checkout/thank-you",
  },
  CHECKOUT_PAYMENT_CONFIRMATION: {
    label: "Payment Confirmation",
    url: "/checkout/payment-confirmation",
  },
  YORUBA_ROADMAP: {
    label: "Yoruba Roadmap",
    url: "/yoruba-roadmap",
  },
  IGBO_ROADMAP: {
    label: "Igbo Roadmap",
    url: "/igbo-roadmap",
  },
  HAUSA_ROADMAP: {
    label: "Hausa Roadmap",
    url: "/hausa-roadmap",
  },
  FAQS: {
    label: "FAQs",
    url: "/faqs",
  },
  DIRECT_TO_PAY_WELCOME: {
    label: "Welcome to Lingawa",
    url: "/welcome/a",
  },
  DIRECT_TO_PAY_MEET_TUTORS: {
    label: "Meet your tutors",
    url: "/welcome/a/get-started",
  },
  DIRECT_TO_PAY_WELCOME_B: {
    label: "Welcome to Lingawa",
    url: "/welcome/b",
  },

  DIRECT_TO_PAY_GET_STARTED_B: {
    label: "Get started",
    url: "/welcome/b/get-started",
  },
  DIRECT_TO_PAY_MEET_TUTORS_B: {
    label: "Meet your tutors",
    url: "/welcome/b/view-tutors",
  },
  CHOOSE_A_LANGUAGE: {
    label: "Choose a language",
    url: "/welcome/choose-a-language",
  },

  //Can we do this
};
