import LazyLoad from "react-lazyload";

export default function BecomeTutor() {
  return (
    <div className="container my-12 relative">
      <div className="p-14 bg-gradient-to-r from-purple-gradient-start lg:h-80 rounded-3xl shadow-md">
        <div className="text-white text-4xl font-bold leading-10">
          Refer a friend
        </div>
        <div className="text-white text-lg mt-4 font-bold">
          Get a lesson, give a lesson!
        </div>
        <div className="text-white text-md mt-4">
          1. Refer a friend to Lingawa
        </div>
        <div className="text-white text-md mt-2">
          2. They get a free lesson to experience our tutors
        </div>
        <div className="text-white text-md mt-2">
          3. You get a free lesson when they complete their first paid lesson
        </div>
        <div className="text-white text-md mt-2">
          4. Repeat. There's no limit!
        </div>
        {/* <NavLink to={routes.BECOME_A_TUTOR.url}>
          <button
            type="button"
            className="flex lg:mt-12 mt-8 justify-between gap-2 items-center bg-secondary hover:bg-secondary-light hover:shadow-xl text-white rounded-2xl
              px-8 py-3 z-10"
          >
            CONTACT US
          </button>
        </NavLink> */}
      </div>
      <div className="absolute bottom-0 right-24 hidden lg:block">
        <LazyLoad>
          <img
            src={`${process.env.REACT_APP_ASSET_CDN}/smiling_tutor.webp`}
            alt=""
            width={350}
          />
        </LazyLoad>
      </div>
    </div>
  );
}
