import { useContext, useEffect, useRef, useState } from "react";
import { IoPlayCircleOutline } from "react-icons/io5";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TrustpilotBadge from "src/components/Shared/TrustpilotBadge";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { useStateValue } from "src/context/StateProvider";
import { IClientCurrencyData } from "src/interfaces/currency";
import { getClientCurrency } from "src/services/currency";
import { responsive } from "src/static-data/direct-to-pay/carousel-responsiveness";
import {
  igboTestimonials,
  yorubaTestimonials,
} from "src/static-data/direct-to-pay/testimonials";
import "../carousel.css";
import LandingPaymentPlan from "../LandingPagePricing/PaymentPlan";
import TestimonialPlayerModal from "../VideoPlayer";

type ITestimonials = {
  name: string;
  videoUrl: string;
  thumbnail?: string;
};

interface ILanding {
  onClick: () => void;
}
export default function Landing({ onClick }: ILanding) {
  const {
    state: { subject, source },
  } = useContext(DirectPayContext);
  const videoRefs = useRef<HTMLVideoElement[]>([]);
  const [{ clientIpCountry, user }] = useStateValue();
  const [testimonials, setTestimonials] = useState<ITestimonials[]>([]);
  const [currency, setCurrency] = useState<IClientCurrencyData | null>(null);
  console.log(currency, "currency ");
  const [isVideoModalOpen, setisVideoModalOpen] = useState(false);
  const [videoDetails, setVideoDetails] = useState<ITestimonials>({
    name: "",
    videoUrl: "",
  });

  const handlePlayClick = (videoDets: ITestimonials) => {
    setVideoDetails(videoDets);
    setisVideoModalOpen(true);
  };
  useEffect(() => {
    if (subject == "yoruba") {
      setTestimonials([...yorubaTestimonials]);
    } else {
      setTestimonials([...igboTestimonials]);
    }
  }, []);
  useEffect(() => {
    if (clientIpCountry) {
      setCurrency(getClientCurrency(clientIpCountry));
    }
  }, [clientIpCountry]);
  return (
    <>
      <div>
        <TestimonialPlayerModal
          videoDetails={videoDetails}
          isVideoModalOpen={isVideoModalOpen}
          setisVideoModalOpen={setisVideoModalOpen}
        />
      </div>
      <section className="text-center h-screen pt-8 sm:absolute top-1/2 sm:-translate-y-1/2 left-8 right-8 ">
        <div className=" flex flex-col md:flex-row justify-between mb-12 max-w-6xl mx-auto">
          <div className=" w-full md:w-1/2">
            <h1 className="font-bold md:text-2xl text-center md:text-left sm:text-xl leading-6 max-w-xl mx-auto">
              <span>{subject === "yoruba" ? "E̩ Káàbò̩" : "Ndeewo"}</span>
            </h1>
            <p className="font-bold text-xl text-center md:text-left sm:text-xl leading-6 max-w-xl mx-auto">
              Welcome to Lingawa -
            </p>
            <p className="font-bold text-xl text-center md:text-left sm:text-xl leading-6 max-w-xl mx-auto">
              Where you can speak your mother <br />
              tongue with pride
            </p>
            <div className="flex justify-start items-center flex-col  md:flex-row  gap-2">
              <div className="flex flex-col">
                <button
                  data-test="d2pGetStartedButton"
                  onClick={onClick}
                  className="pink-button-landing w-full mt-8"
                >
                  Get Started
                </button>
                <button className="rotate-10 transition w-[95%] ease-out duration-300 transform hover:scale-105 gold-button">
                  {` Start learning for just ${
                    currency?.currencyCode === "CAD"
                      ? "$20"
                      : currency?.currencyCode === "GBP"
                      ? "£10"
                      : "$15"
                  }/hr!`}
                </button>
              </div>

              <div className="mt-7">
                <TrustpilotBadge />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-end  lg:flex-row w-full md:w-1/2">
            <p
              className="font-medium text-center md:text-left  leading-7 max-w-xs mx-auto "
              style={{
                textShadow: `-1px -1px 0 #fff,  1px -1px 0 #fff,-1px  1px 0 #fff,1px  1px 0 #fff`,
              }}
            >
              <span className="text-secondary font-medium text-lg">
                Ready to meet your{" "}
                <span> {subject === "yoruba" ? "Yoruba" : "Igbo"}</span> tutor?
              </span>{" "}
              <br />{" "}
              <span className="text-[#414141] max-w-xl">
                In just a few questions, we'll reveal the perfect match for you!
              </span>
            </p>
            <div className="flex md:justify-end">
              <div className="hidden md:flex  items-center justify-center bg_url w-[230px] bg-cover bg-center h-[230px]">
                <p className="max-w-[140px] text-[16px] rotate-21 font-semibold">
                  Your fast track to fluency in{" "}
                  <span>{subject === "yoruba" ? "Yoruba" : "Igbo"}</span> -
                  Speak confidently in a year!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-6xl mx-auto  ">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all 2.5s"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            className="text-center"
          >
            {testimonials.map((testimonial, index) => (
              <div key={index} className="relative inline-block">
                <img
                  src={testimonial.thumbnail}
                  alt={`Thumbnail for ${testimonial.videoUrl}`}
                  style={{ objectPosition: "left center" }}
                  className=" w-[350px] h-[210px] bg-black rounded-3xl object-cover"
                />
                <div
                  className="absolute bottom-1/2 left-[44%]"
                  onClick={() => {
                    handlePlayClick(testimonial);
                  }}
                >
                  <IoPlayCircleOutline className="text-white text-5xl" />
                </div>
                <p className="text-md text-center font-normal text-primary mt-2">
                  {testimonial.name}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="max-w-6xl mt-10 mx-auto  ">
          <LandingPaymentPlan onClick={onClick} />
        </div>
      </section>

      {/* <div className="absolute hidden md:block md:left-28 lg:left-40 top-28">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image4.webp`}
          alt="direct-pay-landing-image4"
          className=" rounded-full w-32 h-32 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute hidden md:block md:right-24 lg:right-48 top-28">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image1.webp`}
          alt="direct-pay-landing-image1"
          className=" rounded-full w-32 h-32 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute hidden md:block left-20 bottom-40">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image2.webp`}
          alt="direct-pay-landing-image2"
          className=" rounded-full w-28 h-28 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div>
      <div className="absolute hidden md:block md:right-32 lg:right-56 bottom-28">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN}/direct-pay-landing-image3.webp`}
          alt="direct-pay-landing-image3"
          className=" rounded-full w-28 h-28 object-cover border-primary border-2 p-1 border-dashed"
        />
      </div> */}
    </>
  );
}
