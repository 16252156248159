import React, { useContext, useEffect } from "react";
import { FaBriefcase, FaStar } from "react-icons/fa";

import { useStateValue } from "src/context/StateProvider";

import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "src/Routes";
import { Tutor } from "src/interfaces/direct-pay";
import { Context as DirectPayContext } from "src/context/DirectPayContext";
import { getTutorPercentageMatch } from "src/services/shared";
import clsx from "clsx";
import {
  renderCorrectEmoji,
  replaceShortcodesInArray,
  replaceSingleShortcodeInArray,
  splitIntoCategories,
} from "src/utils/renderTutorAdminBio";
import { MdRateReview } from "react-icons/md";
import { BsShieldShaded } from "react-icons/bs";

type TutorCardProps = {
  tutor: Tutor;
  index?: number;
};

export default function MeetTutorCard({ tutor, index }: TutorCardProps) {
  const navigate = useNavigate();
  const [{ clientIpCountry }] = useStateValue();

  const {
    state: { onBoarding, isLoading, leadFunnel },
    actions: { updateOnboarding, setAdminBio, setTutorBioCategories },
  } = useContext(DirectPayContext);
  useEffect(() => {
    if (clientIpCountry) {
    }
  }, [clientIpCountry]);

  const categories = splitIntoCategories(tutor?.adminBio);
  const tutorBio = replaceShortcodesInArray(categories);

  const viewTutor = (tutor: Tutor) => {
    if (
      onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-1" ||
      onBoarding.onboarding?.leadFunnel === "direct-to-pay-flow-3"
    ) {
      navigate(
        `${
          routes.VIEW_TUTOR_PROFILE_DIRECT_PAY.url
        }/${tutor.firstName.toLowerCase()}-${tutor.lastName
          .charAt(0)
          .toLowerCase()}/${tutor.id}`
      );
    } else {
      navigate(
        `${
          routes.DIRECT_TO_PAY_MEET_TUTORS_B.url
        }/${tutor.firstName.toLowerCase()}-${tutor.lastName
          .charAt(0)
          .toLowerCase()}/${tutor.id}`
      );
    }
  };

  return (
    <div className="white-card-rounded sm:w-[20rem] p-3">
      {/* Match */}
      <p
        className={clsx({
          "font-bold text-xl leading-7 mb-3 bg-green-100 text-green-600 py-3 px-6 rounded-2xl":
            tutor.percentageMatch > 80,
          "font-bold text-xl leading-7 mb-3 bg-yellow-100 text-yellow-600 py-3 px-6 rounded-2xl":
            tutor.percentageMatch < 80,
        })}
      >
        {Math.round(tutor.percentageMatch)}% Match
      </p>
      <div className="">
        <img
          src={tutor.avatar}
          alt={tutor.firstName}
          className="rounded-xl w-[19rem] h-[17rem] md:h-[18rem] object-cover object-top"
        />
        {/* tutor details */}
        <div className="flex justify-between mt-2 mb-3">
          {/* Name */}
          <p className="text-primary font-semibold text-xl">
            {`${tutor.firstName} ${tutor.lastName.charAt(0)}`}
          </p>
          {/* tutor rating */}
          <div className="flex gap-1 items-center">
            <p className="text-primary mt-[3px] text-lg">
              {tutor.rating?.toFixed(1)}
            </p>
            <FaStar className="text-yellow-500" size={19} />{" "}
          </div>
        </div>
        <div
          className={`flex gap-3 items-center ${
            tutor.designation.length > 25 ? "mb-0" : "mb-0 md:mb-2"
          }`}
        >
          {tutor.numberOfRatings ? (
            <div>
              <div className="flex gap-1 items-center">
                <MdRateReview className="text-secondary" size={15} />{" "}
                <p className="text-primary font-semibold text-xs">
                  {tutor.numberOfRatings}{" "}
                  <span className="font-normal">Reviews</span>
                </p>
              </div>
              <div className="flex gap-1 items-center">
                <FaBriefcase className="text-light-purple-light" size={15} />{" "}
                <p className="text-primary font-semibold text-xs mt-[2px]">
                  {tutor.completedLessons}{" "}
                  <span className="font-normal">Completed Lessons</span>
                </p>
              </div>
            </div>
          ) : (
            <div className="flex gap-1 items-center mt-1">
              <BsShieldShaded className="text-secondary" size={15} />{" "}
              <p className="text-primary font-semibold text-xs mt-[2px]">
                <span className="font-semibold">Lingawa Guarantee</span>
              </p>
            </div>
          )}
        </div>
        {/* Button */}

        <button
          className="purple-button w-full mt-3 flex items-center justify-center"
          onClick={() => {
            updateOnboarding(
              { selectedTutor: tutor },
              onBoarding.onboarding!._id
            );
            setAdminBio(tutorBio);
            setTutorBioCategories(categories);
            viewTutor(tutor);
          }}
          disabled={isLoading}
          data-test="d2pViewTutorButton"
        >
          {isLoading && <div className="loader"></div>}
          View Tutor
        </button>
      </div>
    </div>
  );
}
