import React, { useEffect, useState } from "react";
// import { content } from "./Content/content";
import SentenceBuilder from "./SentenceBuilder/SentenceBuilder";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressBar from "./ProgressBar";
import ResultsPage from "./ResultsPage";
import BetaTag from "src/components/BetaTag";
import { NavLink, useParams } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { LearningAppApi } from "src/api/learning-app";
import { routes } from "src/Routes";

toast.configure();

export default function SentenceConstructionSession() {
  const { userId, sessionId } = useParams();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [session, setSession] = useState<any>(null);
  const [prompts, setPrompts] = useState<any>(null);
  const [sessionTracker, setSessionTracker] = useState<any>(null);
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(0);
  const [currentPrompt, setCurrentPrompt] = useState<any>();
  const [order, setOrder] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const correctAnswerSound = new Audio(
    `${process.env.REACT_APP_ASSET_CDN}/success.wav`
  );

  const getInteractiveSession = async () => {
    if (!sessionId) return;
    const response = await LearningAppApi.getInteractiveSession(sessionId);
    const wordlist = await LearningAppApi.getPromptWords(
      response?.data?.prompts[currentIndex]?.wordBank
    );
    console.log("wordlist", wordlist);
    const session = response?.data?.session;
    setOrder(response?.data?.topic?.order);
    setSession(session);
    const prompts = response?.data?.prompts;
    setSessionTracker(prompts ? JSON.parse(JSON.stringify(prompts)) : null);
    console.log("prompts", prompts);
    setPrompts(response?.data?.prompts);
    let prompt = response?.data?.prompts[currentIndex];
    prompt.wordBank = wordlist?.data?.words;
    setCurrentPrompt(prompt);
    // fetch words for current prompt

    setNumberOfQuestions(response?.data?.prompts?.length);
  };

  useEffect(() => {
    getInteractiveSession();
  }, []);

  // Start timer
  useEffect(() => {
    const newTimer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);

    setTimer(newTimer);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  // Stop timer on quiz completion
  useEffect(() => {
    if (isComplete && timer) {
      clearInterval(timer);
      setTimer(null); // Stop the timer
    }
  }, [isComplete, timer]);

  // Format time from seconds to MM:SS
  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const normalizeSentence = (sentenceArray: any[]) => {
    return sentenceArray
      .map((item) =>
        item.word
          .trim()
          .toLowerCase()
          .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      )
      .filter((word) => word); // This filter removes any empty strings that might result from the normalization
  };

  const evaluateSentenceConstruction = (
    userSentence: any[],
    targetSentences: any
  ) => {
    // Normalize the user's sentence array of objects to a string
    const normalizedUserSentence = normalizeSentence(userSentence).join(" ");

    // Normalize each correct sentence and check if any matches the user's sentence
    const isCorrect = targetSentences
      .map((sentence: string) =>
        sentence
          .toLowerCase()
          .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
          .trim()
      )
      .includes(normalizedUserSentence);

    if (isCorrect) {
      return { isValid: true, feedback: "Correct sentence!" };
    } else {
      return {
        isValid: false,
        feedback: `The sentence is incorrect. Try again!`,
      };
    }
  };

  const handleSubmit = (questionType: string, value: any) => {
    let evaluateAnswer = null;
    if (questionType === "sentence-construction") {
      evaluateAnswer = evaluateSentenceConstruction;
    }
    if (!evaluateAnswer) return;

    const { isValid, feedback } = evaluateAnswer(
      value.sentence,
      value.targetSentences
    );

    // track score
    if (currentIndex < sessionTracker.length) {
      sessionTracker[currentIndex].score = isValid ? 1 : 0;
      setSessionTracker(sessionTracker);
    }

    if (isValid) {
      setTimeout(() => {
        correctAnswerSound.play();
      }, 300);
      toast.success(`Correct: ${value.targetSentences[0]}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    } else {
      toast.error(`Incorrect: ${value.targetSentences[0]}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      // Add the question back to the end of the sessionTracker
      // const newSession = { ...session };
      prompts.push(currentPrompt);
      console.log("prompts", prompts);
      setPrompts(prompts);
      setNumberOfQuestions(prompts.length);
    }

    const onToNextQuestion = async () => {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      if (nextIndex < numberOfQuestions) {
        let nextPrompt = prompts[nextIndex];
        // Check if the next promt word bank is an array of strings or an array of objects
        if (typeof nextPrompt.wordBank[0] === "string") {
          let wordlist = await LearningAppApi.getPromptWords(
            nextPrompt.wordBank
          );
          console.log("next wordlist", wordlist);
          nextPrompt.wordBank = wordlist?.data?.words;
          setCurrentPrompt(nextPrompt);
        } else {
          setCurrentPrompt(nextPrompt);
        }
      } else {
        if (isValid) {
          setIsComplete(true);
          toast.success(`You have completed this session`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      }
    };

    setTimeout(onToNextQuestion, 2000);
  };

  const calculateScorePercentage = () => {
    let totalScore = 0;
    sessionTracker.forEach((question: any) => {
      totalScore += question.score;
    });
    return Math.ceil((totalScore / sessionTracker.length) * 100);
  };

  if (!currentPrompt) return null;

  return (
    <>
      <div className="px-4 relative">
        <div className="-mt-1">
          <ProgressBar
            currentIndex={currentIndex}
            numberOfQuestions={numberOfQuestions}
          />
        </div>
        <div className="flex justify-between items-center py-4">
          <NavLink
            to={`${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}/${session.level}/${order}/${session.topic}/${session.topicId}${routes.SENTENCE_CONSTRUCTION_SELECTION.url}`}
          >
            <div className="text-gray-purple cursor-pointer items-center flex gap-2">
              <div>
                <AiOutlineCloseCircle />
              </div>
              close
            </div>
          </NavLink>
          <div className="max-w-[150px]">
            <BetaTag />
          </div>
        </div>
        {/* <div className="text-primary-medium font-semibold p-2">
        {formatTime(elapsedTime)}
      </div> */}
        {!isComplete && (
          <div>
            {currentPrompt.type === "sentence-construction" && (
              <SentenceBuilder
                question={currentPrompt}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        )}
        {isComplete && (
          <div>
            <ResultsPage
              elapsedTime={formatTime(elapsedTime)}
              score={calculateScorePercentage()}
              session={session}
              order={order}
            />
          </div>
        )}
      </div>
    </>
  );
}
