import React from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import BetaTag from "src/components/BetaTag";
import { routes } from "src/Routes";

export default function BackToResources({
  isBetaVersion,
  sessionType,
}: {
  isBetaVersion?: boolean;
  sessionType?: string;
}) {
  const navigate = useNavigate();
  const { userId, level, topic, order, topicId } = useParams();

  return (
    <div className="flex justify-between items-center">
      <NavLink
        to={`${routes.STUDENT_DASHBOARD_LEARN.url}/${userId}/${level}/${order}/${topic}/${topicId}`}
      >
        <div className="text-gray-purple cursor-pointer items-center flex gap-2">
          <div>
            <MdOutlineKeyboardBackspace />
          </div>
          <p className="hidden text-center md:block"> {"back to resources"}</p>
        </div>
      </NavLink>
      <div className="text-primary font-semibold text-sm md:text-xl">
        {`${order}. ${topic}`}
        <div className="text-sm text-center">{sessionType}</div>
      </div>
      {isBetaVersion && (
        <div className="max-w-[150px]">
          <BetaTag />
        </div>
      )}
    </div>
  );
}
